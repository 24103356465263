$(document).ready ->
  removeBodyClasses = ->
    $('.collapse-body').removeClass('active slide-left slide-right')

  $('.header-main .main-menu-current').click (e) ->
    if window.isMobile()
      e.preventDefault()
      $('.collapse-body').toggleClass('active slide-left')
      $(window).scrollTop(0)
    else if window.location.pathname == e.target.getAttribute('href')
      e.preventDefault()

  $('.collapse-body .page-wrap').on 'swiperight swipeleft', ->
    removeBodyClasses()

  $('[data-behavior=aside-close]').on 'click', ->
    removeBodyClasses()

  $(".hamburger").on "click", ->
    $(@).toggleClass("is-active")