import Rails from '@rails/ujs'
Rails.start()

import $ from 'jquery'
global.$ = $
global.jQuery = $
import _ from '../scripts/frontend/vendors/underscore'
global._ = _

import 'bootstrap'
import '@rails/ujs'

import '../scripts/jquery.form-n-validate'
import '../scripts/frontend/vendors/fotorama'
import '../scripts/frontend/vendors/ion.rangeSlider'
import '../scripts/frontend/google_dfp'

import '../scripts/frontend/vendors/jquery.formstyler.min'
import '../scripts/serialize_object.js'

import '../scripts/frontend/vendors/jquery.scrollTo'
import '../scripts/frontend/vendors/jquery.lazyload'
import '../scripts/frontend/vendors/jquery.maskedinput'
import '../scripts/frontend/vendors/jquery.stickytableheaders'
import '../scripts/frontend/vendors/jquery.mobile.events'
import '../scripts/frontend/vendors/jquery.hoverIntent'
import '../scripts/frontend/vendors/xdLocalStorage'
import '../scripts/frontend/viewport.js'
import '../scripts/frontend/helpers.js'
import '../scripts/frontend/ajax-navigation/constants.js'
import '../scripts/frontend/ajax-navigation/content-controller.js'
import '../scripts/frontend/ajax-navigation/partial-controller.js'
import '../scripts/frontend/ajax-navigation/helpers.js'
import '../scripts/frontend/ajax-navigation/model-calc.js'
import '../scripts/frontend/ajax-navigation/model-compare-controller.js'
import '../scripts/frontend/ajax-navigation/model-controller.js'
import '../scripts/frontend/ajax-navigation/model-list-controller.js'
import '../scripts/frontend/ajax-navigation/model-variant-controller.js'
import '../scripts/frontend/ajax-navigation/state-machine.js'
import '../scripts/frontend/credit-calc.js'
import '../scripts/frontend/lazyload.js'
import '../scripts/frontend/web-consult-opener.js'
import '../scripts/frontend/web-consult-nod'
import '../scripts/frontend/analytics.js'
import '../scripts/frontend/contact-form-opener.js'
#import '../scripts/frontend/compl-currency-switch.js.coffee.erb'
import '../scripts/frontend/seller-contact-forms.js'
import '../scripts/frontend/vehicle-search-form.js'
import '../scripts/frontend/banners.js'
import '../scripts/frontend/top-menu-collapse.js'
import '../scripts/frontend/responsive-tabs.js'
import '../scripts/frontend/back_to_top.js'
import '../scripts/frontend/share-button.js'
import '../scripts/frontend/toggleize.js'
import '../scripts/frontend/modals.js'
import '../scripts/frontend/credit-calc/model-calc.js'
import '../scripts/frontend/credit-calc/universal-calc.js'
import '../scripts/loading'
import '../scripts/frontend/dynamic-yandex-map.js'
import '../scripts/frontend/show-more.js'
import '../scripts/frontend/locations/controller.js'
import '../scripts/frontend/clickable-dom.js'
import '../scripts/frontend/scrollto.js'
import '../scripts/frontend/truncate-text.js'

import '../stylesheets/frontend/application.scss'

require.context('../images/frontend/', true, /\.(gif|jpg|png|svg)$/i)

# Support component names relative to this directory:
componentRequireContext = require.context("components", true);
ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);
