# Класс для работы с интерфейсом городов
class window.LocationUIController
  constructor: (controller) ->
    @controller = controller
    @$menuCityList = $("#menu-city-list")
    @$container = $("[data-behavior='my-location-select']")
    @$suggest = @$container.find('.suggest')

    @isMenuOpen = false
    @cityMenuInitialized = @sugestInputInitialized = false

    @onMenuCityListOnly "shown.bs.collapse", =>
      @initializeCityMenu()
        .then @initializeSuggestInput

    @onMenuCityListOnly "show.bs.collapse", =>
      $(document.body).addClass("locations-collapse")
      $(document.body).on "click", @bodyClickHandler

    @onMenuCityListOnly "hide.bs.collapse", =>
      $(document.body).removeClass("locations-collapse")
      $(document.body).off "click", @bodyClickHandler

    @$menuCityList.find("[data-letter]").addClass "letter"

  # Просто @$menuCityList.on срабатывает и на раскрытие внутренних data-behavior=collapse ("Показать все города"),
  # а нам нужно только для внешнего, большого списка
  onMenuCityListOnly: (event, callback)=>
    @$menuCityList.on event, (e)->
      return if e.target != e.currentTarget
      callback()

  # Инициализация меню выбора города
  initializeCityMenu: =>
    new Promise (resolve, reject) =>
      if @cityMenuInitialized
        return resolve()
      @cityMenuInitialized = true

      @showProgressBar()
        .then @controller.initLocalStorage
        .then @controller.getStoredLocation
        .then @showProgressBar
        .then @setMenuLocation
        .then @controller.getNearestCities
        .then @showNearestCities
        .then resolve
        .catch (e) =>
          @showSuggestInput()
            .then @initializeCityMenu
            .then @initializeSuggestInput
            .then @focusSuggestInput

      @initializeCityFilter()

      @$container.find("[data-behavior='my-location-edit']").on "click", (e) =>
        e.preventDefault()
        if @$suggest.hasClass("in")
          @hideSuggestInput()
        else
          @showSuggestInput()
            .then @initializeCityMenu
            .then @initializeSuggestInput
            .then @focusSuggestInput

      @$container.find("[data-behavior='location-select-close']").on "click", (e) =>
        e.preventDefault()
        @controller.getStoredLocation()
          .then @hideSuggestInput
          .catch =>
            # Юзер не выбрал местоположение и нажал на крестик, значит он согласился
            # с предложенным ему местоположением, запоминаем его и выводим ему ближайшие города.
            @hideSuggestInput()
              .then =>
                @controller.temporaryLocation
              .then @controller.setStoredLocation
              .then @controller.getNearestCities
              .then @showNearestCities

      @$menuCityList.find("[data-behavior=aside-close]").off("click").on "click", =>
        if @isMenuOpen
          @closeMenu()

  initializeSuggestInput: =>
    new Promise (resolve, reject) =>
      if @sugestInputInitialized
        return resolve()
      @sugestInputInitialized = true
      @controller.initializeYandexMapsAPI()
        .then =>
          @suggestView = new ymaps.SuggestView("ymaps-suggest", {boundedBy: @countryBounds, container: @$container[0]})
          @suggestView.events.add("select", @onLocationSelect)
          resolve()

  # Проверка выбранного местоположения (происходит редирект если нужно)
  checkSelectedLocation: (location) =>
    new Promise (resolve, reject) =>
      currentCity = @$menuCityList.find("li.selected").data("name")
      locationLC = String(location.name).toLowerCase()
      if currentCity == String(location.name).toLowerCase()
        # Текущий открытый город совпадает с городом, который мы получили через гео-функции
        # нужно закрыть меню выбора города, юзер просто продолжает просмотр
        @hideModal().then @closeMenu
      else if @$menuCityList.find("li[data-name='#{locationLC}']").length > 0
        # Город, который мы получили через Гео-функции присутствует в списке городов с салонами,
        # в таком случае перекидываем его на этот URL
        window.location.href = @$menuCityList.find("li[data-name='#{locationLC}'] a").attr("href")
        return reject()

      resolve(location)

  bodyClickHandler: (e) =>
    if $(e.target).parents("#menu-city-list").length == 0 && e.target.id != "back-to-top"
      @$menuCityList.collapse("hide")

  # Выставление местоположения в окошке. Выставляется основное название.
  setMenuLocation: (location) =>
    new Promise (resolve, reject) =>
      shortName = location.name
      if shortName.length > 30
        shortName = shortName.substr(0, 30) + "…"
      @$container.find("[data-behavior='my-location-name']").html(shortName)
      @$suggest.css {left: @$container.find(".current-location")[0].offsetWidth}

      resolve location

  # Отображение крутилки
  showProgressBar: (location) =>
    new Promise (resolve, reject) =>
      @$container.find(".nearest-locations").html("")
      if location
        @$container.find(".nearest-container").addClass("in")
      $("<div class='spinner'>").appendTo @$container.find(".nearest-locations")
      resolve(location)

  # Закрытие меню
  closeMenu: =>
    new Promise (resolve, reject) =>
      @$menuCityList.collapse("hide")
      setTimeout resolve, ANConstants.ANIM_DURATION

  # Открытие меню
  openMenu: =>
    new Promise (resolve, reject) =>
      @$menuCityList.collapse("show")
      setTimeout resolve, ANConstants.ANIM_DURATION

  # Обработчик выбора города из выпадашки яндекса
  onLocationSelect: (e) =>
    # При выборе элемента из списка подсказок мы получаем только текст. Нужно произвести геокодирование для получения координат.
    item = e.get("item")

    @showProgressBar(true)
      .then ->
        item.value
      .then @controller.geoCode
      .then @hideSuggestInput
      .then @setMenuLocation
      .then @controller.setStoredLocation
      .then @checkSelectedLocation
      .then @controller.getNearestCities
      .then @showNearestCities
      .catch (e) ->
        console.log(e)

  # Подсветка символов при фильтре города
  highlightTextIndex: ($li, escapedValue) ->
    indexFrom = -1
    # Подсветка символов красным в названии города происходит с позиции,
    # найденной в первом попавшемся аттрибуте data-name, data-name-en
    # или data-name-en2, на длину, равную количеству введённых символов
    if ($li.data("name").indexOf(escapedValue) != -1)
      indexFrom = $li.data("name").indexOf(escapedValue)
    else if ($li.data("name-en").indexOf(escapedValue) != -1)
      indexFrom = $li.data("name-en").indexOf(escapedValue)
    else if ($li.data("name-en2").indexOf(escapedValue) != -1)
      indexFrom = $li.data("name-en2").indexOf(escapedValue)

    indexFrom

  # Инициализация фильтра городов
  initializeCityFilter: =>
    controller = @
    @$menuCityList.find("[data-behavior=city-filter]").each ->
      $this = $(this)
      $citiesList = $("[data-behavior=city-list]")
      $input = $this.find("input")
      $citiesList.find("li").each ->
        $(this).data "original-name", $(this).find("span").html()

      if window.isMobile()
        # Для мобилы фильтр списка
        $input.on "keyup", ->
          rawValue = this.value

          escapedValue = rawValue.replace(/(['])/g,"\\$1").toLowerCase()

          $citiesList.find("li").removeClass("match")
          $matchCities = $citiesList.find("[data-name*='#{escapedValue}'], [data-name-en*='#{escapedValue}'], [data-name-en2*='#{escapedValue}']").addClass("match").each ->
            $this = $(@)
            $span = $this.find("a span")
            indexFrom = controller.highlightTextIndex($this, escapedValue)

            textToHighlight = $(@).data("original-name")
            if indexFrom > -1
              $span.html("#{textToHighlight.substr(0, indexFrom)}<span class='highlight'>#{textToHighlight.substr(indexFrom, rawValue.length)}</span>#{textToHighlight.substr(indexFrom + rawValue.length)}")

          setTimeout ->
            $citiesList.find(".visible:not(.match)").removeClass("visible")
            $matchCities.addClass("visible")
          , 10

          if rawValue.length > 0
            $citiesList.addClass("filtering")
          else
            $citiesList.removeClass("filtering")
            $citiesList.find("li").each ->
              $(@).find("a span").html $(@).data("original-name")

      else
        # Для десктопа окно с подсказками
        $results = $this.find("[data-behavior=city-filter-results]")
        $input.on "keyup", ->
          $results.html("")
          $ul = $("<ul>").appendTo($results)
          rawValue = this.value
          escapedValue = rawValue.replace(/(['])/g,"\\$1").toLowerCase()

          $citiesList.find(".menu-city-list.available").find("[data-name*='#{escapedValue}'], [data-name-en*='#{escapedValue}'], [data-name-en2*='#{escapedValue}']").each ->
            $newLi = $(this).clone()
            $link = $newLi.find("a")
            $span = $link.find("a span")
            indexFrom = controller.highlightTextIndex($newLi, escapedValue)

            $link.attr("class", "")

            textToHighlight = $span.text()
            if indexFrom > -1
              $span.html("#{textToHighlight.substr(0, indexFrom + 1)}<span class='highlight'>#{textToHighlight.substr(indexFrom + 1, rawValue.length)}</span>#{textToHighlight.substr(indexFrom + rawValue.length + 1)}")

            $newLi.appendTo($ul)

  # Фокусирование поля поиска города
  focusSuggestInput: =>
    @$container.find("#ymaps-suggest").val("").focus()

  # Вывод поискового поля для ввода названия местоположения
  showSuggestInput: =>
    new Promise (resolve, reject) =>
      @controller.getStoredLocation()
        .then (location) =>
          if location && location.fullName
            @$suggest.find("input").attr("placeholder", location.fullName)
        .catch ->
          # do nothing
      $nearestContainer = @$container.find(".nearest-container")
      marginLeft = parseInt @$suggest.css("margin-left")
      width = $nearestContainer[0].offsetWidth - marginLeft + 20 # запас на невидимые пробелы в HTML
      width = 250 if width < 250
      @$suggest
        .css {display: "inline-block", left: @$container.find(".current-location")[0].offsetWidth, width: 0}

      setTimeout =>
        @$suggest.css {width: width}

        setTimeout ->
          resolve()
        , ANConstants.ANIM_DURATION
      , 10

      setTimeout =>
        @$suggest.addClass "in"
      , 200

  # Скрытие поискового поля местоположения
  hideSuggestInput: (data) =>
    new Promise (resolve, reject) =>
      @$suggest
        .css {width: 0}
        .removeClass "in"

      setTimeout ->
        resolve(data)
      , ANConstants.ANIM_DURATION

  # Прокрутка окна наверх
  scrollToTop: ->
    new Promise (resolve, reject) =>
      if document.documentElement.scrollTop > 0
        $(window).scrollTo 0, ANConstants.ANIM_DURATION, axis: "y", onAfter: resolve
      else
        resolve()

  showModal: (location) =>
    new Promise (resolve, reject) =>
      @$modal = $("#location-is-correct-modal")
      @$modal.on "shown.bs.modal", ->
        resolve(location)
      @$modal.modal({backdrop: 'static', keyboard: false})

  hideModal: =>
    new Promise (resolve, reject) =>
      if @$modal.is(".show")
        @$modal.on("hidden.bs.modal", resolve)
        @$modal.modal("hide")
      else
        resolve()

  # Вывод модалки "Вы находитесь в XXX?" "Да" / "Нет"
  askForCorrectLocation: (location) =>
    @modalOpen = true
    @controller.temporaryLocation = location

    $modalDialog = @$modal.find(".modal-dialog")
    $modalContent = @$modal.find(".modal-content")

    $modalContent.find("h2").html("Ваш город " + location.name + "?")
    @$modal.find(".location-correct").on "click", (e) =>
      e.preventDefault()
      escapedLocationName = LocationController.addSlashes(location.name.toLowerCase())
      if location.name.toLowerCase() == @$menuCityList.find("li.selected").data("name").toLowerCase()
        # Если мы определили его город как текущий открытый город, то просто запоминаем местоположение
        # и закрываем модалку. От юзера больше ничего не требуется.
        if @isMenuOpen
          # Если модалка открыта, то подгружаем ближайшие города для выбранного местоположения
          @contoller.setStoredLocation(location)
            .then @hideModal
            .then ->
              location
            .then @controller.getNearestCities
            .then @showNearestCities
        else
          # Если модалка закрыта, то местоположения подгрузятся когда модалка будет открыта,
          # поэтому просто закрываем модалку.
          @controller.setStoredLocation(location)
            .then @hideModal
      else if @$menuCityList.find("li[data-name='#{escapedLocationName}']").length > 0
        # Если мы определили его город, и у нас есть этот город с автосалонами, то
        # производим редирект сразу на него
        @showProgressBar()
          .then ->
            location
          .then @controller.setStoredLocation
          .then =>
            window.location = @$menuCityList.find("li[data-name='#{escapedLocationName}']").find("a").attr("href")
      else
        # Если мы определили его город (в нём нет автосалонов), открываем меню выбора
        # города и он должен там увидеть ближайшие к нему, чтобы переключиться.
        @showProgressBar()
          .then ->
            location
          .then @controller.setStoredLocation
          .then @hideModal
          .then @openMenu
          .then ->
            location
          .then @controller.getNearestCities
          .then @showNearestCities
          .catch (e) ->
            console.log(e)

    @$modal.find(".location-incorrect").on "click", (e) =>
      e.preventDefault()
      @hideModal()
        .then @openMenu
        .then @showSuggestInput
        .then @initializeCityMenu
        .then @initializeSuggestInput
        .then @focusSuggestInput
        .catch (e) ->
          console.log(e)

  # Вывод ближайших городов с автосалонами
  showNearestCities: (nearestCities) =>
    @$container.find(".nearest-container").addClass("in")
    @$container.find(".nearest-locations").html("")

    # такой странный способ получения элементов из-за того, что нам важен порядок селекторов
    # если jQuery скормить список айдишников через запятую, то он вытащит их в том порядке,
    # в котором они находятся в DOM, а нам нужно в том порядке, в котором они отсортированы в списке.
    cities = []
    $.each nearestCities, (item) ->
      cityAnchor = $("#" + @ + " a")
      cityAnchor.attr("data-id", $("#" + @).attr("id"))
      cities.push cityAnchor[0]

    controller = @controller
    $(cities).clone().appendTo(@$container.find(".nearest-locations")).each ->
      $(@).find("sup").remove()
      # Добавляем расстояние в километрах
      id = $(@).data("id")
      if controller.nearestText[id]
        if controller.nearestDistance[id] && controller.nearestDistance[id] > 20000
          $("<sup>").attr("class", "ml-1").html(controller.nearestText[id]).appendTo @
        else
          $("<sup>").attr("class", "ml-1").html("вы здесь").appendTo @

    currentHref = @$menuCityList.find("li.selected a").attr("href")
    hasSelected = false
    @$container.find(".nearest-locations a").each ->
      if this.href == currentHref
        $(@).addClass("selected").on "click", ->
          return false
        hasSelected = true

    $items = @$container.find(".nearest-locations a span")
    counter = 0
    interval = setInterval =>
      $items.eq(counter).addClass("in")
      counter++
      clearInterval(interval) if counter > $items.length
    , ANConstants.ANIM_DURATION/2

