window.googletag = window.googletag || {};
googletag.cmd = googletag.cmd || [];
(function() {
  var gads = document.createElement('script');
  gads.async = true;
  gads.type = 'text/javascript';
  var useSSL = 'https:' == document.location.protocol;
  gads.src = (useSSL ? 'https:' : 'http:') +
    '//www.googletagservices.com/tag/js/gpt.js';
  var node = document.getElementsByTagName('script')[0];
  node.parentNode.insertBefore(gads, node);
})();

googletag.cmd.push(function() {
  // dynamic banner load requires SRA
  googletag.pubads().enableSingleRequest();

  // Disable initial load, we will use refresh() to fetch ads.
  // Calling this function means that display() calls just
  // register the slot as ready, but do not fetch ads for it.
  googletag.pubads().disableInitialLoad();

  // Enable services
  googletag.enableServices();
});

// Realize onetime events for googletag
// Used for displaing div with ad-slot if content present.
window.googletag_onetime_events = [];
googletag.cmd.push(function() {
  googletag.pubads().addEventListener('slotRenderEnded', function (event) {
    for (var i = 0; i < window.googletag_onetime_events.length; i++) {
      if(event.slot.getSlotElementId() === window.googletag_onetime_events[i][0]) {
        var cb = window.googletag_onetime_events[i][1];
        window.googletag_onetime_events.splice(i, 1);
        cb(event);
      }
    };
  });
});