window.initUniversalCreditCalc = (dom_id, ajax_url, amount_val, percents_by_months_data, currency_symbol, currency_abr)->
  $dom = $("#" + dom_id)
  $form = $dom.find('form:eq(0)')

  $dom.find('.model-calc-form-fields').show()

  parse_dirty_number = (str)->
    if str
      parseFloat(str.toString().replace(/[^\.0-9]/g, ""))
    else
      0

  number_with_spaces = (x)->
    Math.round(x).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")

  get_form_data = ->
    $form.serializeObject()?['credit_order']

  calc_monthly_payment = ->
    form_data = get_form_data()

    $.ajax
      url: ajax_url,
      data:
        price: parse_dirty_number(form_data.car_price)
        months: parse_dirty_number(form_data.months)
        first_payment_percent: parseInt($form.find("input[name='credit_order[first_payment]']").attr('percent'))
        options: form_data.options

  $privacy = $form.find('input[type=checkbox][name=privacy]')

  sync_months = ->
    $form.find('#universal-calc__months-current').html $form.find('.universal-calc__months-input:checked').val() + ' мес.'

  $form.find('.universal-calc__months-input').on 'click', sync_months

  sync_months()

  first_payment_amount = (x)->
    Math.round(parseInt(amount_val) * x / 100)

  percents_by_months = percents_by_months_data
  first_payment_slider = (current_months)->
    $slider = $form.find('.js-range-slider')

    first_payment      = parseInt($form.find("input[name='credit_order[first_payment]']").val())
    first_payment_full = number_with_spaces(first_payment) + currency_symbol

    percents = percents_by_months[1 * current_months]
    from_fixed = false
    if percents.length <= 1
      from_fixed = true
      percents   = [0, percents[0]]

    highest_percent = percents[percents.length - 1]
    max = Math.round(1.0 * get_form_data().car_price * highest_percent / 100)

    on_slider = (data)->
      from_value = data.from_value
      from_value = highest_percent if !from_value && from_value != 0
      first_payment_field = $form.find('#credit_order_first_payment')
      fp_amount           = first_payment_amount(from_value)

      # обновляем значение в форму заявки
      first_payment_field.attr('percent', from_value)
      first_payment_field.val(fp_amount)

      # обновляем значение первоначального взноса в рамках комплектации
      first_payment_full = number_with_spaces(fp_amount) + currency_symbol
      $form.closest('.model-calc-form').parent().find('span#universal-calc__first-payment.universal-calc__current').text(first_payment_full)

    $slider.data('ionRangeSlider')?.destroy()
    $slider.ionRangeSlider
      from: max
      grid: true
      grid_num: percents.length,
      values: percents
      from_fixed: from_fixed
      onStart: on_slider
      onChange: (data)->
        on_slider(data)
        recalculate()

  $form.find('.universal-calc__months-input').on('change', (e)-> first_payment_slider(e.target.value))

  toggleFormByPrivacy = ->
    $form.find('input[type=submit]').prop('disabled', !$privacy.is(':checked'))

  $privacy.on('change', toggleFormByPrivacy)
  toggleFormByPrivacy()

  recalculate = ->
    calc_monthly_payment()
      .done (res)->
        monthly   = res.monthly_payment.value[currency_abr]
        services  = $form.find("input[name='credit_order[options][service_ids][]']:checked").map(-> $(@).closest('label').text().trim()).toArray()

        # ставим значение в форму заявки
        $form.find('.credit-monthly-field').val(monthly)
        $form.find('.credit-services-field').val(services.join(', '))

        # обновляем все значения ежемесячного платежа в рамках комплектации
        monthly_payment_full = number_with_spaces(monthly) + currency_symbol
        $form.closest('.model-calc-form').parent().find('span.credit-monthly.universal-calc__total').text(monthly_payment_full)

      .fail ->
        alert 'Ошибка расчета. Пожалуйста, попробуйте еще раз позднее.'

  $form.find('.model-calc-form-toggles input[type=radio], .universal-calc__additional input[type=checkbox]').bind 'change', recalculate
  $form.find('.input-default-period').trigger('change')

  $form.on 'ajax:before', ->
    reject = false

    # отправка события в Google Analytics на клике кнопки отправить в форме заявки
    calc_id = $(@).find('input[type=submit]').attr('data-behavior-name')
    window.gtag('event', 'credit-calc-send', {
      event_category: 'Views',
      event_action: 'credit-calc-send',
      event_label: calc_id
    }) if window.gtag

    # проверяем, что поля заполнены
    $(@).find('.required').each ->
      unless $(@).val()
        reject = true
        $(@).addClass('is-invalid')
    if reject
      alert('Необходимо заполнить обязательные поля.')
      $submitButton = $form.find("input[type=submit]")
      setTimeout ->
        $.rails.enableElement($submitButton[0])
        $submitButton.prop("disabled", false)
      , 1000
      return false

  $form.on 'ajax:success', (e) ->
    data = e.detail[0]
    $(@).find('.credit-universal-calc-send-form').hide()
    if data && data.status == 'error'
      $(@).find('.model-calc-form-error').show()
    else
      $(@).find('.model-calc-form-complete').show()

  $form.on 'ajax:error', ->
    alert('Извините, но при отправке заявки возникла ошибка. Немного подождите и попробуйте снова.')

  $form.find('[data-behavior=phone]').each ->
    $phone = $(@)
    return if $phone.data('masked')
    $phone.data('masked', true).mask('+7 999 999 99 99', placeholder: ' ')

  $form.find('[data-behavior=iin]').each ->
    $iin = $(@)
    return if $iin.data('masked')
    $iin.data('masked', true).mask('999 999 999 999', placeholder: ' ')
