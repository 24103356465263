class window.ANModelCompareController
  constructor: ->
    @$tab = $(".tabs-content-compare").eq(0)
    @$help = @$tab.find(".compare-help").eq(0)

    @$tab.find("[data-behavior=compare-open-help]").on "click", (e) =>
      e.preventDefault()
      $.scrollIntoView(@$tab, ANConstants.TR_TOP_MARGIN, ANConstants.ANIM_DURATION).then =>
        @$help.removeClass("d-none")
        @$help.find("[data-behavior=compare-deny-help], [data-behavior=compare-start-help]").hide()
        $movie = @$help.find(".movie")
        if $movie.hasClass("started")
          @reloadHelpImage()
        else
          $movie.addClass("started")

    @$tab.find("[data-behavior=compare-start-help]").on "click", (e) =>
      e.preventDefault()
      $.scrollIntoView(@$tab, ANConstants.TR_TOP_MARGIN, ANConstants.ANIM_DURATION).then =>
        @$help.find("[data-behavior=compare-deny-help], [data-behavior=compare-start-help]").hide()
        @$help.find(".movie").addClass("started")

    @$tab.find("[data-behavior=compare-close-help], [data-behavior=compare-deny-help]").on "click", (e) =>
      e.preventDefault()
      @$help.addClass("d-none")
      document.cookie = "#{window.compareHelpShowedCookieName}=1; path=/";

  open: () ->
    ReactRailsUJS.mountComponents() if !$("table.compare").length
    if window.stateMachine.compare_set
      window.stateMachine.compare_set ->
        new URLSearchParams(location.search)

  reloadHelpImage: ->
    @$help.find(".movie").each ->
      # "Перезагружаем" гифку, чтобы она проигралась заново
      if $(@).css("background-image").search(/url\(['"]?([^'"]+)['"]?\)/g) > -1
        bgUrl = RegExp.$1.split("?")[0]
        $(@).css("background-image", "url('#{bgUrl}?#{new Date().getTime()}')")