$(document).on "click", "[data-toggle=modal][data-href]", (e) ->
  e.preventDefault()
  header = ""
  size_class = ""

  if $(@).data("close-button")
    header += "<button type='button' class='close' data-dismiss='modal'>"+
                "<span aria-hidden='false' style='color: #0b0b0b'>&times;</span></button>"
  if $(@).data("size")
    size_class = "modal-" + $(@).data("size")

  if header
    header = "<div class='modal-header'>" + header + "</div>"

  $modal = $(
    "<div class='modal' role='dialog'>" +
      "<div class='modal-dialog " + size_class + "'>" +
        "<div class='modal-content'>" +
          header + 
          "<div class='modal-body'></div>" +
        "</div>"+
      "</div>"
    ).appendTo(document.body)

  if $(@).data("body-id")
    $modal.find(".modal-body").attr("id", $(@).data("body-id"))
  if $(@).data("class")
    $modal.addClass($(@).data("class"))

  window.showLoadingIndicator()
  $.ajax
    url: $(@).data("href")
    complete: (data) ->
      $modal.find(".modal-body").html(data.responseText)
      $modal.modal("show")
      window.hideLoadingIndicator()
