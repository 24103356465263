# Проскролить окно до указанного элемента.
#   $what:     до куда скролить (jquery-объект)
#   topMargin: сколько отступить от верха окна до указанного элемента
#   duration:  сколько миллисекунд скролить
#   threshold: если разница между текущей и желаемой позицией скрола меньше, чем это число,
#              скролить не нужно (против бессмысленных микроскролов по 20 пикселей)
#
$.scrollIntoView = ($what, topMargin, duration, threshold = 50) ->
  deferred = $.Deferred()
  top = $what.offset().top
  diff = top - $(document).scrollTop() - topMargin
  if diff > threshold || diff < 0 && (diff * -1) > topMargin
    $(window).scrollTo(top - topMargin, duration, axis: "y", onAfter: ->
      deferred.resolve()
    )
  else
    deferred.resolve()
  deferred

# Аналог Russian.pluralize из гема russian.
#
window.russianPlural = (value, v1, v2, v5)->
  mod10 = value % 10
  mod100 = Math.floor((value % 100) / 10)

  if mod100 != 1 and mod10 == 1
    v1
  else if mod100 != 1 and mod10 > 1 and mod10 < 5
    v2
  else
    v5 || v2
