class window.ANModelVariantController
  constructor: (controller, id, handle, contentContainer, url, title)->
    @id = id
    @controller = controller

    @$handle                = $(handle)
    @$handleContainer       = @$handle.closest(".row.link")
    @$contentContainer      = $(contentContainer)

    @url    = url || decodeURI(@$handle.attr("href"))
    @title  = title || null

    @openedTab    = null
    @openedCalc   = null

    @initialUrl = decodeURI(document.location.pathname)

    @$handle.on "click", $.proxy((e)->
      e.preventDefault()
      e.stopPropagation()

      if $(e.currentTarget).closest(".row.link").hasClass("current")
        window.stateMachine.pushState({model: @controller.id}, @controller.url)
      else
        window.stateMachine.pushState({model: @controller.id, variant: @id}, @url)
    , @)

    @controller.variantsMap[id] = @

    if @$contentContainer.size() != 0
      @initTabs()
      @initLinks()
      @initCalc()

  currentState: ->
    model:      @controller.id,
    variant:    @id,
    variantTab: @openedTab,
    calc:       @openedCalc,

  mergeCurrentState: (mergeState)->
    $.extend(@currentState(), mergeState)

  stateUrl: (state)->
    url_parts = @url.split("#")
    url_parts = url_parts[0].split("?").concat([url_parts[1]])

    url_suffix = []
    url_suffix.push("?" + url_parts[1]) if url_parts[1]
    url_suffix.push("#" + url_parts[2]) if url_parts[2]

    parts = []
    parts.push(url_parts[0].replace(/\/$/, ""))
    parts.push(state.variantTab) if state.variantTab && state.variantTab != "options"
    parts.push("calcs/#{state.calc}") if state.calc
    parts.join("/") + url_suffix.join()

  changeStateMerged: (changeType, mergeState)->
    newState = @mergeCurrentState(mergeState)
    window.stateMachine[changeType](newState, @stateUrl(newState))

  pushMergedState: (mergeState)->
    @changeStateMerged("pushState", mergeState)

  replaceMergedState: (mergeState)->
    @changeStateMerged("replaceState", mergeState)

  open: ->
    deferred = $.Deferred()

    # Этот код нужен для того, чтобы контроллер не отрабатывал при
    # раскрытии калькулятора
    return deferred.resolve() unless @$contentContainer.size() == 0

    @controller.unhighlightVariant()

    if @controller.openedVariant
      $oldVariantContainer = @controller.openedVariant.$contentContainer
      $oldVariantHandleContainer = @controller.openedVariant.$handleContainer
      prevHeight = $oldVariantContainer.outerHeight(true)
      prevHeight += $oldVariantHandleContainer.outerHeight(true) if window.isMobile()
      prevOffsetTop = $oldVariantContainer.offset().top if $oldVariantContainer.offset()
      @controller.openedVariant.close()
      prevHeight -= $oldVariantHandleContainer.outerHeight(true) if window.isMobile()
    else
      prevHeight = 0
      prevOffsetTop = 0

    @$contentContainer = $("""<div class="tabs-content-description row"></div>""")

    $row = @$handle.closest(".row.link")
    $row.addClass("current")

    # компенсируем (мгновенно прокручивая окно) высоту скрываемого блока другой комплектухи, если он выше текущего
    if $row.offset().top > prevOffsetTop
      $(document).scrollTop($(document).scrollTop() - prevHeight)

    @$contentContainer.insertAfter($row).show()
    $.scrollIntoView(@$contentContainer, 200, 200)
    @controller.openedVariant = @

    @$contentContainer.addClass("loading").load randomizedUrl(@url), =>
      @title = @$contentContainer.find("title").text()
      @$contentContainer.removeClass("loading")
      @initTabs()
      @initLinks()
      @initCalc()

      deferred.resolve()

    return deferred

  close: ->
    @$handle.closest(".row.link").removeClass("current")
    @$contentContainer.remove()
    @$contentContainer = $()
    @controller.openedVariant = null

  initTabs: ->
    controller = @

    @$contentContainer.find("[data-behavior=toggle-active-seller]").on "click", (e)->
      return true if $(e.originalEvent.target).is("a") || $(@).hasClass("active")

      if $(@).hasClass("active")
        $(@).removeClass("active")
      else
        parentSelector = $(@).data("parent")
        if parentSelector
          # дочерние элементы находятся в разных ветках
          $(@).parents(parentSelector).find(".active").removeClass("active")
        else
          # дочерние элементы находятся на одном уровне
          $(@).parent().children(".active").removeClass("active")
        $(@).addClass("active")

    # обработаем клик с анимацией здесь, а не через pushState -> stateMachine -> controller.close(),
    # потому что иначе будет несколько анимаций, выглядит дико
    @$contentContainer.find("[data-behavior=close-variant]").on "click", (e)->
      controller.controller.highlightOpenedVariant()
      $currentRow = $(@).closest(".tabs-content-description")
      $targetRow = $currentRow.prev()
      $.scrollIntoView($targetRow, ANConstants.TR_TOP_MARGIN, ANConstants.ANIM_DURATION)
      $currentRow.animate({height: "0px"}, ANConstants.ANIM_DURATION, "linear", ()->
        $targetRow.find("a").trigger("click")
      )

  # подключение поведения ajax-ссылок из содержимого
  initLinks: ->
    controller = @
    # Ссылки для переключения между вкладками изнутри содержимого комплектации
    @$contentContainer.find("[data-behavior=open-model-tab]").on "click", (e)->
      e.preventDefault()
      window.stateMachine.pushState({model: controller.controller.id, tab: $(@).data("tab")}, decodeURI(@.href))
      $.scrollIntoView($("#up300"), ANConstants.TR_TOP_MARGIN, ANConstants.ANIM_DURATION)

  initCalc: ->
    controller = @

    pathArray = @initialUrl.split("/").filter((e) -> e)
    if pathArray[pathArray.length - 2] == "calcs"
      calcId = pathArray[pathArray.length - 1]
      controller.openCalc calcId
    else
      controller.openCalc null

    @$contentContainer.on "click", "[data-behavior=model-calc-open]", (e)->
      e.preventDefault()
      controller.pushMergedState(calc: $(@).attr("calc-id"))

    @$contentContainer.on "click", "[data-behavior=model-calc-close]", (e)->
      e.preventDefault()
      controller.pushMergedState(calc: null)

  toggleTab: (which)->
    $ul = @$contentContainer.find(".tabs-description-panel:eq(0)")
    $ul.children(".active").removeClass("active")

  openCalc: (calcId)->
    if calcId
      # отправка события в Google Analytics на открытие калюкулятора
      label = @$contentContainer.find(".model-calc-active a").attr('data-behavior-name')
      window.gtagGaEvent(eventCategory: 'Views', eventAction: 'credit-calc-open', eventLabel: label) if window.gtagGaEvent

      # не только показываем кальк, но и скрываем переключатель других, так как в связи с аяксовой навигацией очень
      # сложная логика переключения выходит (если кальк еще не открыт, надо просто сменить на соседний, а если открыт,
      # надо пройти весь цикл pushState -> openCalc, так как должен поменяться УРЛ)
      @$contentContainer.find("[calc-id=#{calcId}]").hide().next(".model-calc-form").show()
      @$contentContainer.find(".all-model-calcs-list").hide()
    else
      @$contentContainer.find(".model-calc-form").hide().prev(".model-calc-header").show()
      @$contentContainer.find(".all-model-calcs-list").show()

    @openedCalc = calcId