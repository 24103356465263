class window.ANPartialController
  constructor: (controller, initialData, gaParams, ymParams) ->
    @controller = controller
    if !controller.partial
      # Фикс бага с кнопкой "Назад"
      url = decodeURI(location.href)
      initialData.url = url
      @controller.replaceState({partial: initialData, initialize: true}, url)
    @controller.partial = @
    # Доп. параметры Google Analytics
    @gaParams = gaParams
    # Доп. параметры Yandex.Metrkia
    @ymParams = ymParams
    @referer = location.href

  open: (data)->
    deferred = $.Deferred()

    $container = $(data.container)
    window.showLoadingIndicator()

    controller = @controller
    $.ajax
      type: "GET"
      url: data.url
      data:
        only_content: 1
      dataType: "html"
      success: (result_data) =>
        $container.html(result_data)
        title = $container.find("title").text()
        controller.setTitle(title)
        ["h1", ".breadcrumbs"].forEach (selector) ->
          if $container.find(selector).length > 0
            $(selector + ":visible").html($container.find(selector).html())

        try
          window.gtag(@gaParams)

        try
          window.metrika().hit(data.url, title, @referer, @ymParams)

        @referer = location.href

      complete: ->
        window.hideLoadingIndicator()
        deferred.resolve()

    return deferred
