# Adds the method serializeObject to jQuery, to perform complex form serialization into JavaScript objects.
#
# https://github.com/macek/jquery-serialize-object
# http://stackoverflow.com/questions/1184624/convert-form-data-to-js-object-with-jquery/8407771#8407771?newreg=3a8dc6a6358d4d3f9a7d0a083cf8d3cd
#
#  Usage
#
#  $('#my-form').serializeObject();
#
#  <!-- all of these will work! -->
#  <input name="honey[badger]" value="a">
#  <input name="wombat[]" value="b">
#  <input name="hello[panda][]" value="c">
#  <input name="animals[0][name]" value="d">
#  <input name="animals[0][breed]" value="e">
#  <input name="crazy[1][][wonky]" value="f">
#  <input name="dream[as][vividly][as][you][can]" value="g">
#
#  // output
#  {
#  "honey":{
#    "badger":"a"
#  },
#  "wombat":["b"],
#  "hello":{
#    "panda":["c"]
#  },
#  "animals":[
#    {
#      "name":"d",
#      "breed":"e"
#    }
#  ],
#  "crazy":[
#    null,
#    [
#      {"wonky":"f"}
#    ]
#  ],
#  "dream":{
#    "as":{
#      "vividly":{
#        "as":{
#          "you":{
#            "can":"g"
#          }
#        }
#      }
#    }
#  }
#  }




$.fn.serializeObject = ->

  json = {}
  push_counters = {}
  patterns =
    validate  : /^[a-zA-Z][a-zA-Z0-9_-]*(?:\[(?:\d*|[a-zA-Z0-9_-]+)\])*$/,
    key       : /[a-zA-Z0-9_-]+|(?=\[\])/g,
    push      : /^$/,
    fixed     : /^\d+$/,
    named     : /^[a-zA-Z0-9_-]+$/

  @build = (base, key, value) ->
    base[key] = value
    base

  @push_counter = (key) ->
    push_counters[key] = 0 if push_counters[key] is undefined
    push_counters[key]++

  $.each $(@).serializeArray(), (i, elem) =>
    return unless patterns.validate.test(elem.name)

    keys = elem.name.match patterns.key
    merge = elem.value
    reverse_key = elem.name

    while (k = keys.pop()) isnt undefined

      if patterns.push.test k
        re = new RegExp("\\[#{k}\\]$")
        reverse_key = reverse_key.replace re, ''
        merge = @build [], @push_counter(reverse_key), merge

      else if patterns.fixed.test k
        merge = @build [], k, merge

      else if patterns.named.test k
        merge = @build {}, k, merge

    json = $.extend true, json, merge

  return json