# вытаскиваем пункт из спрятанного списка и ставим его в начало. Например, если пользователь
# выбирает непопулярную марку, мы ее извлекаем из списка и ставим в начало.
moveItemToTheBeginningOfTheList = (item, container)->
  semiHidden = item.offsetTop + item.offsetHeight > container[0].offsetTop + container[0].clientHeight
  hidden = item.offsetLeft + item.offsetWidth > container[0].offsetLeft + container[0].offsetWidth
  move = semiHidden || hidden || $(item).is(":hidden")
  if move
    $(item).detach().prependTo(container)
  move

convertRange = (value) ->
  value = value.toString().replace /\s/g, "" # удаление пробелов
  value = value.replace ",", "."             # замена знака 'запятая' на 'точку', js не понимает тип

  value = parseFloat(value)

  if value % 1 != 0
    if value > 0 and value < 1 or value >= 1 and value < 100
      value *= 10**6
  else
    if value >= 100 and value < 1000
      value *= 10**3
    else if value >= 1 and value < 100
      value *= 10**6

  value

$(document).on "click", ".toggleize-item", (e)->
  e.preventDefault()

  if $(@).is(".more-item")
    return false
  $el = $(@).closest("li")
  $container = $el.closest("[data-behavior=toggleize]")
  noResize = false

  $collapse = $(@).closest(".collapse")
  if $collapse.data("parent")
    # Если произошёл выбор из списка "всех" опций и популярные со всеми работают
    # по принципу аккордеона, то закрываем все и открываем популярные
    if $collapse.is("[data-role=toggleize-all]")
      $accordeon = $($collapse.data("parent"))
      $popular = $accordeon.find(".collapse:first")
      $popular.collapse("show")
      $.scrollIntoView($popular, ANConstants.TR_TOP_MARGIN, ANConstants.ANIM_DURATION)
        .then ->
          setTimeout ->
            $container.trigger("toggleize.resize")
          , ANConstants.ANIM_DURATION * 2
    noResize = true
  else
    # просто сворачиваем список
    $collapse.collapse("hide")

  select = $($container.data("select"))

  if $el.hasClass("disabled") and $container.data("force-show-all") != true and !$el.hasClass("current")
    return false

  $formLine = $(@).closest(".form-line")

  if $el.hasClass("current")
    $el.removeClass("current")
    if select.is("input[type=checkbox]")
      select.prop("checked", false)
    else
      select.val("")
    $(@).trigger("toggleize.deselect")
    $formLine = $(@).closest(".form-line")
    $formLine.find("[data-role=current-option]").html("")
    $formLine.find("input[type=radio]").prop("checked", false)

  else
    if select.is("input[type=checkbox]")
      select.prop("checked", true)
    else
      select.val($el.data("value"))

    $container.find(".current").removeClass("current")
    $el.addClass("current")
    $(@).trigger("toggleize.select")
    $(@).closest(".form-line").find("[data-role=current-option]").html($(@).data("name"))
    $radio = $(@).find("input[type=radio]")
    if $radio.length > 0 && !$radio.prop("checked")
      $formLine.find("input[type=radio]").removeAttr("checked")
      $radio.prop("checked", true)

  select.trigger("change")

  $container.trigger("toggleize.resize") if !noResize
  false # только так, новый jQuery не останавливает событие через e.preventDefault()

$(document).on "click", ".toggleize-item a", (e)->
  $(@).parent().trigger("click")
  false

$(document).on "click", ".toggleize-item input[type=checkbox], .toggleize-item input[type=radio]", (e)->
  setTimeout =>
    $(@).closest(".toggleize-item").trigger("click")
  , 10
  false

$(document).on "click", "[data-behavior=toggleize] .more", (e)->
  e.preventDefault()
  $(@).closest("[data-behavior=toggleize]").find(".all").toggle()

$(document).on "toggleize.resize", "[data-behavior=toggleize]", (e)->
  $head = $(@).find(".toggleize-head")
  $select = $($(@).data("select"))
  $list = $head.find("ul")

  if $(@).data("behavior-sort-items")
    # Выдвигаем вперёд выбранный элемент, если он есть
    if $select.val()
      $item = $(@).find(".toggleize-head").find(".toggleize-item[data-value='" + $select.val() + "']")
      $item.addClass("current")
      moveItemToTheBeginningOfTheList($item[0], $list)
      $list = $head.find("ul")

    sortByPriority = (a, b) ->
      aW = parseInt($(a).data("priority"))
      bW = parseInt($(a).data("priority"))
      return 1 if aW > bW || isNaN(aW)
      return -1 if aW < bW
      return 0

    filterOnlyVisible = (index, item) ->
      item.offsetTop == 0

    sortByName = (a, b) ->
      aN = $(a).data("value")
      bN = $(b).data("value")
      -("" + aN).localeCompare(bN)

    # Сортируем все элементы по весу, добавляем в заголовок и получаем количество видимых
    sortedList = $list.children(":not(.more-item)").toArray().sort(sortByPriority)

    sortedList.forEach (item) ->
      $(item).detach().appendTo($list)

    # Затем сортируем видимые элементы по-алфавиту
    visibleItems = $list.children(":visible:not(.no-more):not(.disabled)").filter(filterOnlyVisible).toArray().sort(sortByName)

    visibleItems.forEach (item) ->
      $(item).detach().prependTo($list)

    # Если есть элемент "показать все", то двигаем его всегда в конец списка
    if $list.find(".more-item").length > 0
      $list.find(".more-item").detach().appendTo($list)

$(document).on "keyup", "#price_range_from, #price_range_to", (e)->
  if @.value && @.value.length > 0
    @.value = @.value.replace /[^\d\s.,]/g, ""

    $converted  = convertRange(@.value)
    $formLine   = $(@).closest(".form-line")
    $inputRange = $formLine.find(".free-price_range")
    $inputRange.attr("data-#{@.id}", $converted)

    if @.id == "price_range_from"
      $from = $converted
      $till = $inputRange.data("price_range_to")
    else
      $from = $inputRange.data("price_range_from")
      $till = $converted

    $shortRangeEntryForm = $.map([$from, $till], (price) ->
      parseFloat(parseInt(price) / 10**6).toString().replace(".", ",")
    )

    if $from && $till
      $inputRangeDataName = $shortRangeEntryForm.join("-")
    else if $from
      $inputRangeDataName = "от #{$shortRangeEntryForm[0]}"
    else
      $inputRangeDataName = "до #{$shortRangeEntryForm[1]}"

    $inputRangeValue = [$from, $till].join("-")
    $inputRange.attr
      "data-name": "#{$inputRangeDataName} млн"
      "data-priority": "1"
      "data-value": $inputRangeValue

    $inputRange.find("input[type=hidden]").val($inputRangeValue)

# прячем/показываем ссылку "все" для наших стилизованных селектов в зависимости от размеров окна
$(window).on "resize", -> $("[data-behavior=toggleize]").trigger("toggleize.resize")

$(document).on "click", "a[data-role=current-option]", (e)->
  # "Отжимание" выбранной опции.
  $(@).html("")
  $select = $($(@).data("target"))

  $select.val("")
  $container = $("[data-select='##{$select.attr("id")}']")
  $container.find(".current").removeClass("current")
  $container.find("input[type=radio]").prop("checked", false)

  $select.trigger("change")

  # чтобы произошёл и e.preventDefault() и cancelBubble, возвращаем false, как это принято в jQuery.
  false

$(document).on "keyup", "input[data-role=toggleize-filter]", (e)->
  $toggleizeAll = $(@).parents("[data-role=toggleize-all]").first()

  if @.value && @.value.length > 0
    seekValue = @.value.toLowerCase()
    $toggleizeAll.find(".toggleize-item:not(.filtered-out)").filter ->
      return false if $(@).data("value").indexOf(seekValue) != -1 || $(@).data("name").indexOf(seekValue) != -1 || $(@).data("ru1").indexOf(seekValue) != -1
      true
    .addClass("filtered-out")
  else
    $toggleizeAll.find(".toggleize-item.filtered-out").removeClass("filtered-out")

$(document).on "hide.bs.collapse", ".toggleize-all", (e)->
  if $(@).offset().top < $(window).scrollTop()
    $.scrollIntoView($(@), ANConstants.TR_TOP_MARGIN, ANConstants.ANIM_DURATION)
