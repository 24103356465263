$(document).on "change", ".model-calc-form-toggle", ->
  $(@).closest(".model-calc-form-toggles").find(".model-calc-form-toggle.active").removeClass("active")
  $(@).closest(".model-calc-form-toggle").addClass("active")

$(document).on "click", ".model-calc-form-toggle a", (e)->
  e.preventDefault()
  $(@).closest(".model-calc-form-toggle").find("input[type=radio]").prop("checked", true).trigger("change")

$(document).on "click", "[data-behavior=model-calc-next]", (e)->
  e.preventDefault()
  changeCalc($(@))

$(document).on "click", "[data-behavior=model-calc-all-click]", (e)->
  e.preventDefault()
  document.location = $("a", @).attr("href")