class window.ANContentController
  constructor: (container, baseState, baseUrl)->
    @$container = $(container)
    @baseState = baseState || {}
    @baseUrl = baseUrl || decodeURI(window.document.location.href)
    @titles = {}

    @$container.on "click", "[data-behavior=open-content-in-announce]", (e)=>
      e.preventDefault()
      if $(e.currentTarget).closest(".container-content").hasClass("content-opened")
        window.stateMachine.pushState(@baseState, @baseUrl)
      else
        window.stateMachine.pushState(@mergeBaseState({content: $(e.currentTarget).data("id")}), decodeURI($(e.currentTarget).attr("href")))

    @$container.on "click", ".content-opened-close-btn", (e)=>
      e.preventDefault()
      window.stateMachine.pushState(@baseState, @baseUrl)

  mergeBaseState: (newState)->
    r = {}
    r[k] = v for k, v of @baseState
    r[k] = v for k, v of newState
    r

  openContent: (id)->
    deferred = $.Deferred()

    @closeOpenedContent()

    $contentContainer = @$container.find("#content-" + id)
    $placeholder = $("<div class=\"content-opened-body loading\"></div>")
    $contentContainer.addClass("content-opened bg-white")
    $contentContainer.find(".text-content").append($placeholder)
    $contentContainer.find(".content-header").addClass("content-header-small")
    $contentContainer.find(".description").removeClass("col-lg-8").addClass("col-lg-12")

    $.scrollIntoView($placeholder, 100, 300)

    $.ajax
      type: 'GET'
      url: @$container.find("[data-behavior=open-content-in-announce][data-id=" + id + "]").attr("href")
      data:
        only_content: 1
      dataType: 'html'
      success: (data) ->
        $placeholder.html(data)
        # @titles[id] = $placeholder.find("title").text()
      complete: ->
        $placeholder.removeClass("loading")
        $closeBtn = $("<a href='#' class='content-opened-close-btn btn-close'></a>")
        $contentContainer.append($closeBtn)
        deferred.resolve()

    return deferred

  closeOpenedContent: ->
    @$container.find(".content-opened").removeClass("content-opened bg-white").find(".content-header-small").removeClass("content-header-small")
    @$container.find(".content-opened-body").remove()
    @$container.find(".content-opened-close-btn").remove()
    @$container.find(".description").removeClass("col-lg-12").addClass("col-lg-8")