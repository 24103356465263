window.showBannerContainer = (bannerId) ->
  bannerId = "#" + bannerId.replace(/(\.)/g, "\\$1")
  $(bannerId).show();

# Добавление баннеров adFox ломает главный поток JavaScript
# если у пользователя стоит блокировщик рекламы. Добавляем баннера через
# такой wrapper. Также этот враппер отслеживает момент загрузки и
# прорисовки баннеров на странице
window.yaContextCb = window.yaContextCb || []
window.adfoxCreateAdaptive = () ->
  args = arguments
  window.yaContextCb.push ->
    Ya.adfoxCode.createAdaptive.apply(this, args)

$ ->
  $(document).on "click", "[data-behavior*=slide_bnr]", (e)->
    e.preventDefault()

    slider = $(@)
    bannerBody = slider.parent().find(".bnr-body")
    url = slider.data("href")

    $.get(url).done (response)=>
      bannerBody.animate({opacity: 0.1}, 100, ->
        try
          bannerBody.html(response)
      ).animate({opacity: 1}, 100)
