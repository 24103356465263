import "../constants.js"
import "./ui.js"
import "../vendors/xdLocalStorage.js"

class window.LocationController
  constructor: (blankPage, apiKey, countryBounds) ->
    @blankPage = blankPage
    @apiKey = apiKey
    @countryBounds = countryBounds
    @ymapsInitCallbacks = []
    @ymapsInitialized = false
    @temporaryLocation = false

    # При первом открытии пытаемся получить "запомненное" местоположение.
    # Если мы его получили, то ничего делать не надо.
    #@initLocalStorage()
    #  .then @getStoredLocation
    #  .catch =>
    #    # Получаем координаты юзера через яндекс (он определяет по IP)
    #    @initializeYandexMapsAPI()
    #      .then @requestYandexLocation
    #      .then @geoCode
    #      .then @ui.setMenuLocation
    #      .then @ui.showModal
    #      .then @ui.askForCorrectLocation
    #      .catch (data) ->
    #        console?.log("error", data)

    @ui = new LocationUIController(@)

    window.showAndOpenCityMenu = =>
      @ui.openMenu()
        .then @ui.initializeCityMenu

      $(window).scrollTo(0, ANConstants.ANIM_DURATION, axis: "y")

  # Инициализация Локального хранилища
  initLocalStorage: =>
    new Promise (resolve, reject) =>
      if xdLocalStorage.wasInit()
        resolve()
      else
        xdLocalStorage.init {
          iframeUrl: @blankPage,
          initCallback: resolve
        }

  # Получение местоположения из хранилища
  getStoredLocation: ->
    new Promise (resolve, reject) ->
      if localStorage["location"]
        resolve(JSON.parse localStorage["location"])
      else
        xdLocalStorage.getItem "location", (data) ->
          if data.value
            try
              location = JSON.parse data.value
              if String(typeof(location)).toLowerCase() == "object" && location.name && location.coords
                resolve(location)
                return
            catch
          reject()

  # Сохранение местоположения в хранилище
  setStoredLocation: (location) =>
    new Promise (resolve, reject) =>
      xdLocalStorage.setItem "location", JSON.stringify(location), ->
        resolve(location)

  # Запрос на получение геопозиции из браузера
  requestDeviceLocation: ->
    new Promise (resolve, reject) ->
      try
        navigator.geolocation.getCurrentPosition(resolve, reject)
      catch
        reject("no stored location")

  # Запрос на получение геопозиции из яндекса
  requestYandexLocation: ->
    new Promise (resolve, reject) ->
      try
        ymaps.geolocation.get({autoReverseGeocode: true, provider: "yandex", timeout: 5000})
          .then (data) ->
            resolve data.geoObjects.position
      catch e
        reject(e)

  # Инициализация API яндекс карт
  initializeYandexMapsAPI: =>
    new Promise (resolve, reject) =>
      return resolve() if @ymapsInitialized

      if @ymapsInitCallbacks.length > 0
        @ymapsInitCallbacks.push resolve
        return

      @ymapsInitCallbacks.push resolve
      $script = $("<script>")
      .attr("src", "https://api-maps.yandex.ru/2.1?apikey=#{@apiKey}&lang=ru_RU&load=geocode,GeocodeResult,geolocation,suggest,SuggestView&loadByRequire=1&onload=ymInit")
      .attr("async", true)
      .attr("defer", true)

      window.ymInit = =>
        try
          controller = @
          ymaps.load =>
            $.each @ymapsInitCallbacks, ->
              this.apply(controller)
            @ymapsInitCallbacks = []
            @ymapsInitialized = true
        catch
          reject()

      $script.appendTo(document.body)

  # Геокодирование через яндекс, получение названия места по координатам, либо наоборот
  geoCode: (coords) =>
    new Promise (resolve, reject) =>
      @initializeYandexMapsAPI()
        .then ->
          ymaps.geocode(coords, {kind: "locality", results: 1}).then (res) ->
            if res.geoObjects.getLength() > 0
              res.geoObjects.each (item) ->
                resolve {
                  name: item.properties.getAll().name,
                  fullName: item.properties.getAll().text,
                  coords: item.geometry.getCoordinates()
                }
            else
              reject("empty geocoding result")

  # Ключ для кеширования в LocalStorage данных по расстоянию
  routeCacheKey: (coordsFrom, coordsTo) ->
    String(coordsFrom.join("-") + "." + coordsTo.join("-"))

  # Получение закешированных данных по расстоянию из xdLocalStorage
  getCachedRoute: (coordsFrom, coordsTo) =>
    new Promise (resolve, reject) =>
      @initLocalStorage()
        .then =>
          xdLocalStorage.getItem @routeCacheKey(coordsFrom, coordsTo), (data) ->
            if data.value
              try
                route = JSON.parse data.value
                if String(typeof(route)).toLowerCase() == "object" && route.length > 0
                  resolve(route)
                  return
              catch
            reject()

  # Запись в кеш расстояния между двумя точками по дорогам
  setCachedRoute: (coordsFrom, coordsTo, route) =>
    new Promise (resolve, reject) =>
      cacheKey = 
      xdLocalStorage.setItem @routeCacheKey(coordsFrom, coordsTo), JSON.stringify(route), ->
        resolve(route)

  # Получение расстояния по дорогам между двумя точкам из яндекса
  getYaRouteDistance: (coordsFrom, coordsTo) =>
    new Promise (resolve, reject) =>
      @initializeYandexMapsAPI()
        .then ->
          multiRouteModel = new ymaps.multiRouter.MultiRouteModel([
              coordsFrom,
              coordsTo
            ])

          multiRouteModel.events.add ["requestsuccess"], (e) =>
            routes = e.originalEvent.target.getRoutes()
            if routes.length > 0
              return resolve [routes[0].properties.get("distance").value, routes[0].properties.get("distance").text]

            reject()

          multiRouteModel.events.add ["requestfail"], (e) =>
            reject(e)

          new ymaps.multiRouter.MultiRoute(multiRouteModel, { results: 1})
        .catch (e) ->
          reject(e)

  # Получение ближайших городов с салонами для выбранного местоположения
  getNearestCities: (location) =>
    new Promise (resolve, reject) =>
      @initializeYandexMapsAPI()
        .then =>
          @nearestText = {}
          @nearestDistance = {}
          promises = []
          controller = @
          cityCoords = {}
          currentLocation = location.name.toLowerCase()

          @ui.$menuCityList.find("li").each ->
            return if $(@).data("name") == currentLocation
            cityCoords[@id] = $(@).data("coords")
            controller.nearestDistance[@id] = ymaps.coordSystem.geo.getDistance(location.coords, cityCoords[@id])
            controller.nearestText[@id] = LocationController.formatDistance(controller.nearestDistance[@id])

          citiesIDs = Object.keys(controller.nearestDistance)
          citiesIDs.sort (a, b) ->
            controller.nearestDistance[a] - controller.nearestDistance[b]

          # Защита от "бородатых" пекинцев и москвичей. Не рассчитываем расстояние по дорогам если указано местоположение
          # дальше чем 1000 км от ближайшего города с салоном по "воздуху"
          if controller.nearestDistance[citiesIDs[0]] < 1000000
            # Определяем расстояние по дорогам у ближайших по воздуху семи городов
            $.each citiesIDs.slice(0, 8), ->
              id = @
              # сперва пробуем получить данные из "кеша", чтобы запросы к яндексу на
              # получение расстояния по дорогам отправлялись один раз
              p = controller.getCachedRoute(location.coords, cityCoords[id])
                .then (data) ->
                  controller.nearestText[id] = data[1]
                  controller.nearestDistance[id] = data[0]
                .catch ->
                  # Если данные не получены из кеша, то производим запрос в яндекс
                  controller.getYaRouteDistance(location.coords, cityCoords[id]).then (data) ->
                    controller.nearestText[id] = data[1]
                    controller.nearestDistance[id] = data[0]
                    controller.setCachedRoute(location.coords, cityCoords[id], data)
              promises.push p

          Promise.all(promises).then ->
            newCitiesIDs = Object.keys controller.nearestDistance
            newCitiesIDs.sort (a, b) ->
              controller.nearestDistance[a] - controller.nearestDistance[b]

            resolve newCitiesIDs.slice(0, 5)
          .catch ->
            # По каким-то причинам маршруты через яндекс не прошли, возвращаем расстояние по воздуху
            resolve citiesIDs.slice(0, 5)

  # Форматирование расстояния из метров в километры.
  @formatDistance: (distance) ->
    d = Math.round(distance/1000)
    "✈" + d.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " км"

  @addSlashes: (str) ->
    (str+"").replace(/([\\"'])/g, "\\$1").replace(/\0/g, "\\0")