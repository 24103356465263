$.CreditCalc = class CreditCalc
  CreditCalc = @

  @currency_symbol: (currency)->
    switch currency
      when 'usd' then 'долларов'
      when 'kzt' then 'тенге'
      when 'rub' then 'рублей'
      when 'eur' then 'евро'
      else currency

  @credit_calc_annuity: (amount, percent, months)->
    month_percent = percent / 1200

    K = (month_percent + month_percent/(Math.pow(1 + month_percent, months)-1));
    per_month = Math.round(amount * K * 100) / 100
    total = Math.round(months * per_month * 100) / 100

    {
      amount: amount,
      months: months,
      percent: percent,
      k: K,
      monthly: per_month,
      total: total,
      overpayment: Math.round(total - amount)
    }

  @parse_dirty_number: (str)->
    if str
      parseFloat( str.toString().replace(/[^\.0-9]/g,'') )
    else
      0

  @number_with_spaces: (x)->
    Math.round(x).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")



  @init_credit_calc: (container)->
    #console.log('init')

    container.data('percent', CreditCalc.parse_dirty_number(container.data('percent')) )
    container.data('first_payment_percent', CreditCalc.parse_dirty_number(container.data('first-payment-percent')) )

    CreditCalc.set_currency(container, container.find('.credit-currency-field').val())
    CreditCalc.recalc_first_payment(container) if container.data('currency-change-first-payment')
    CreditCalc.recalc_credit(container) if container.data('calc-on-init')

    unless container.data('events_initiated')
      #console.log('events_initiated')
      # если init вызывают повторно не плодим кучу событий, которые вызывают пересчетов
      container.data('events_initiated', true)
      container.find('.credit-first-payment, .credit-months, .credit-amount-field').on "change", ->
        CreditCalc.recalc_credit(container)
      container.find('.credit-first-payment, .credit-months, .credit-amount-field').on "keyup", ->
        CreditCalc.recalc_credit(container)

      container.find('.credit-amount-switchable').on "change", ->
        CreditCalc.set_amount(container, CreditCalc.parse_dirty_number($(@).text().replace('&nbsp;', '')))
        CreditCalc.recalc_first_payment(container) if container.data('currency-change-first-payment')
        CreditCalc.recalc_credit(container)

      container.find('[data-behavior=change_compl_currency]').on "change", ->
        CreditCalc.set_currency(container, $(@).data('currency'))
        container.find('.credit-amount-switchable').trigger('change')

      container.find('.required').on "change", ->
        if $(@).val()
          $(@).removeClass('b-red')
          $(@).parent().find('span.red').remove()



  @set_amount: (container, amount) ->
    #console.log("set amount #{amount}")
    if amount == 0
      amount = ''
    container.find('.credit-amount-field').val(amount)

  @set_currency: (container, currency) ->
    #console.log("set currency #{currency}")
    container.find('.credit-currency-field').val(currency)
    container.find('.credit-currency').text(CreditCalc.currency_symbol(currency))

  @recalc_first_payment: (container) ->
    #console.log('recalc_first_payment')
    amount = CreditCalc.get_amount(container)
    first_payment = Math.round( amount / 100 * container.data('first_payment_percent') )
    if first_payment <= 0
      first_payment = ''
    container.find('.credit-first-payment').val( first_payment )

  @get_amount: (container)->
    amount_element = container.find('.credit-amount-field')
    amount_element_err = container.find('.credit-amount-err')

    amount = CreditCalc.parse_dirty_number( amount_element.val() )

    if amount == 0 or amount == ''
      amount_element.addClass('b-red')
      amount_element_err.text(amount_element_err.data('required'))
    else
      amount_element.removeClass('b-red')
      amount_element_err.text('')

    amount



  @get_first_payment: (container, amount)->
    unless amount
      amount = CreditCalc.get_amount(container)

    first_payment_field = container.find('.credit-first-payment')
    first_payment_field_err = container.find('.credit-first-payment-err')
    first_payment = CreditCalc.parse_dirty_number(first_payment_field.val())

    if first_payment_field.val() != first_payment.toString()
      if first_payment <= 0
        first_payment = ''
      first_payment_field.val(first_payment)

    # проверяем значение поля первоначального платежа
    recommended_first_payment = amount / 100 * container.data('first_payment_percent')
    if first_payment == 0 or first_payment == ''
      first_payment_field.addClass('b-red')
      first_payment_field_err.text(first_payment_field_err.data('required'))
    else if first_payment > amount and amount > 0
      first_payment_field.addClass('b-red')
      first_payment_field_err.text(first_payment_field_err.data('max'))
      first_payment = amount
    else if first_payment + 1 < recommended_first_payment
      first_payment_field.addClass('b-red')
      first_payment_field_err.text(first_payment_field_err.data('min'))
    else
      first_payment_field.removeClass('b-red')
      first_payment_field_err.text('')

    first_payment


  @get_months: (container)->
    months_element = container.find('.credit-months')
    months_element_err =  container.find('.credit-months-err')

    months = CreditCalc.parse_dirty_number(months_element.val())
    min = parseInt(months_element.data('min'))
    max = parseInt(months_element.data('max'))

    months_element.removeClass('b-red')
    months_element_err.text('')

    if months == 0 or months == ''
      months_element.addClass('b-red')
      months_element_err.text(months_element_err.data('required'))
    else if min > 0 and max > 0
      if months < min
        months_element.addClass('b-red')
        months_element_err.text(months_element_err.data('min'))
      else if months > max
        months_element.addClass('b-red')
        months_element_err.text(months_element_err.data('max'))

    months


  @recalc_credit: (container)->
    #console.log('recalc')
    amount = CreditCalc.get_amount(container)
    months = CreditCalc.get_months(container)
    first_payment = CreditCalc.get_first_payment(container, amount)

    if months > 0 and amount - first_payment > 0
      data = CreditCalc.credit_calc_annuity( amount - first_payment, container.data('percent'), months )
    else
      data = {overpayment: 0, monthly: 0}

    container.find('.credit-overpayment').text( CreditCalc.number_with_spaces(data.overpayment) )
    container.find('.credit-monthly').text( CreditCalc.number_with_spaces(data.monthly) )
    container.find('.credit-monthly-field').val( data.monthly )



$(document).on "click", "[data-behavior=open-credit-calc]", (e, data)->
  e.preventDefault()

  calc = $(@).closest('.credit-calc-wrap')
  calc.find('.credit-calc-btn-1').addClass('hide')
  calc.find('.credit-calc-inner').removeClass('hide')
  calc.find('input, select').styler()


$(document).on "click", "[data-behavior=close-credit-calc]", (e, data)->
  e.preventDefault()

  calc = $(@).closest('.credit-calc-wrap')
  calc.find('.credit-calc-btn-1').removeClass('hide')
  calc.find('.credit-calc-inner').addClass('hide')



$(document).on "click", "[data-behavior=open-credit-form]", (e, data)->
  e.preventDefault()

  calc = $(@).closest('.credit-calc-wrap')
  calc.find('.credit-calc-btn-2').addClass('hide')
  calc.find('.credit-calc-send-form-inner').removeClass('hide')
  calc.find('.credit-calc-send-form-notice').removeClass('hide')



$(document).on "ajax:before", ".credit-calc-form", (e, data, status, xhr) ->
  reject = false
  # проверяем, что поля заполнены
  $(@).find('.required').each ->
    unless $(@).val()
      reject = true
      $(@).addClass('b-red')

  if reject
    alert('Необходимо заполнить обязательные поля — они выделены красным.')
    e.preventDefault()
    return false

$(document).on "ajax:success", ".credit-calc-form", (e, data, status, xhr) ->
  calc = $(@).closest('.credit-calc-wrap')
  calc.find('.credit-calc-send-form-inner').addClass('hide')
  calc.find('.credit-calc-send-form-msg').remove()
  calc.find('.credit-calc-inner').append('<div class="credit-calc-send-form credit-calc-send-form-msg"><h4>Ваша заявка успешно отправлена!</h4></div>')

$(document).on "ajax:error", ".credit-calc-form", (e, data, status, xhr) ->
  calc = $(@).closest('.credit-calc-wrap')
  calc.find('.credit-calc-send-form-msg').remove()
  calc.find('.credit-calc-inner').append('<div class="credit-calc-send-form credit-calc-send-form-msg"><h4>Произошла ошибка. Перегрузите страницу и попробуйте еще раз.</h4></div>')