class window.VehicleSearchForm
  constructor: (form)->
    @$form = form
    @$ajaxSearchButton = @$form[0].searchButton = @$form.find("#ajax-search-btn")

    @$form.find(".toggleize-checkbox input[type='checkbox']").on "change", ->
      $("input[name=#{@.name}]:not(##{@.id}").prop("checked", false).parent().removeClass("checked")
      $(@).parent().toggleClass("checked")
      VehicleSearchForm.triggerSearchFormSubmit $(@).closest("form")

    @$form.find(".mobile-free-range-select .free-price_range").on "click", (e) ->
      e.preventDefault()
      VehicleSearchForm.triggerSearchFormSubmit $(@).closest("form")

    @$ajaxSearchButton.click ->
      VehicleSearchForm.triggerSearchFormSubmit($(@).closest("form"), true)
      false

  @triggerSearchFormSubmit: ($form, doSubmit) ->
    isAjax = false
    isAjax = $form[0].searchButton.is(":visible") && !doSubmit if $form[0].searchButton
    # не отправляем пустые параметры
    $("select, input", $form).each ->
      if $(@).val() == "" || @.type == "radio"
        $(@).attr("disabled", "disabled")

    if isAjax
      $form.find(".ui-overlap").removeClass("d-none")
      # Ага, кто-нибудь таки прикрутит аякс
      $("#ajax-search-btn a").html("&nbsp;").addClass("loading")
      $.ajax({
        url: $form.data("ajax-action"),
        type: "GET",
        data: $form.serializeArray(),
        success: (data) ->
          $form.parents(".search-main").replaceWith(data)
          new VehicleSearchForm($(".search-main form"))
        complete: ->
          $form.find(".ui-overlap").addClass("d-none")
      })
    else if $form.data('behavior') == 'vendors-select-form'
      $vendor = $form.find("[name=vendor_name]:eq(0)")
      vendor  = $vendor.val()
      if vendor
        url = $form.data("form-prefix") + vendor.toLowerCase()
      else
        url = $form.attr("action")

      stateMachine.pushState({partial: {container: "[data-behavior=seller-list]", url: url}}, url)
    else
      $vendor = $form.find("[name=vendor]:eq(0)")

      if $vendor.length > 0 && $form.data("form-prefix")
        vendor  = $vendor.val().toLowerCase()
        if vendor
          $form.attr("action", $form.data("form-prefix") + '/' + vendor.toLowerCase())
        $vendor.prop("disabled", true)

      # убираем ? при выборе марки без доп. параметров, пример /cars/audi? -> /cars/audi
      return if $form.serialize() == '' then window.location = $form.attr('action') else $form.submit()

$ ->
  $(document).on "change", "select.toggleize", (e) ->
    VehicleSearchForm.triggerSearchFormSubmit($(@).closest("form"))

  if $('.search-main form').length > 0
    new VehicleSearchForm($('.search-main form'))
