$ ->
  $(document).on "click", "[data-behavior=seller-contact-form-toggle]", ->
    $form = $(@).closest("[data-behavior=seller-contact-form]")
    return if $form.hasClass("sent")
    $form.toggleClass("active")
    $('[data-behavior=seller-contact-form]').not($form).removeClass("active")

  $(document).on "ajax:before", "[data-behavior=seller-contact-form]", (e, data, status, xhr) ->
    $form = $(@)
    reject = false
    $form.find("input[required], textarea[required]").each ->
      unless $(@).val()
        reject = true
        $(@).css("border", "1px solid red")
    if reject
      alert("Необходимо заполнить обязательные поля.")
      e.preventDefault()
      return false

  $(document).on "ajax:success", "[data-behavior=seller-contact-form]", (e, data, status, xhr) ->
    $form = $(@)
    container = $form.closest(".js-contact-container")
    if(data["ok"]==1)
      $form.addClass("sent")
      $form.find("[data-behavior=seller-contact-form-success-text]").html(data["msg"])
    else
      alert("Извините, форма не прошла валидацию.")

  $(document).on "ajax:error", "[data-behavior=seller-contact-form]", (e, data, status, xhr) ->
    alert("Извините, но при отправке формы возникла ошибка. Немного подождите и попробуйте снова.")