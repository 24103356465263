$ ->
  window.updateResponsiveTabs = (tabs_container)  ->
    menu = tabs_container.find('ul')
    items = menu.find('li')
    openup = $('#openup')

    current_tab = menu.find('.tabs-current')
    openup.text(current_tab.find('a').text())
    items.show()
    current_tab.hide()
    if items.length == 1
      openup.addClass('only-label-tab')

  if window.isMobile()
    tabs_container = $('.responsive_tabs')
    updateResponsiveTabs(tabs_container) if tabs_container.length

    $('#openup').on 'click', (e) ->
      e.preventDefault()
      $('.responsive_tabs ul').slideToggle(300)