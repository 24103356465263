window.urlWithParam = (url, key, value)->
  [
    url,
    if url.indexOf("?") >= 0 then "&" else "?",
    key,
    if value then "=#{value}" else ""
  ].join("")

window.randomizedUrl = (url)->
  urlWithParam(url, Math.random())

window.actAsDesktop = ->
  # смотрим, что будет, если отключить
  return false

  #  threshold = 767
  #  retval    = screen.width > threshold
  #  window.actAsDesktop = (-> retval)
  #  retval

window.isMobile = ->
  retval = !!navigator.userAgent.match(/Mobi/) && !window.actAsDesktop()
  window.isMobile = (-> retval)
  retval