class window.ANConstants
  # При анимированных прокрутках страница прокручивается чуть выше требуемого 
  # элемента на это значение, в пикселях
  @TR_TOP_MARGIN: 75

  # Длительность анимаций для скролла страницы, расхлопов, таблицы сравнения и т.д.
  @ANIM_DURATION: 300

  # Отступ от верха экрана до таблицы, при плавной прокрутке
  @TABLE_SCROLL_MARGIN: 50

  # Таймаут анимации прокрутки таблицы
  @TABLE_SCROLL_TIMEOUT: 300

  # Таймаут анимации (должен быть равен длительности transition в CSS)
  @ANIMATION_TIMEOUT: 500

  # Дополнительная задержка для DOM на полную отрисовку скрытия/отображения
  # элементов, а также максимальная задержка для анимации, при превышении которой
  # анимация отключается.
  @DELAY_TIMEOUT: 300
