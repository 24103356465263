window.changeCalc = (calc)->
  return if calc.hasClass("active")

  calc.closest(".all-model-calcs-list").find("a.active").removeClass("active")
  calc.addClass("active")

  $nextCalc = $("#" + calc.data("calc-id"))
  $calcs = $nextCalc.closest(".model-calcs")
  $calc = $calcs.find(".model-calc-active")

  $nextCalc.insertAfter($calc)

  # на мобиле не нужны анимации переходов, быстро меняем
  if window.isMobile()
    $nextCalc.addClass("model-calc-active")
    $calc.removeClass("model-calc-active")
  else
    prevML = "110%"
    newML = "0%"

    $nextCalc.addClass("model-calc-active").css("marginLeft", prevML).animate {marginLeft: newML}, duration: 400, complete: ->
      $calc.removeClass("model-calc-active")