# Прокрутка страницы при клике на ссылку с data-toggle=scrollto до элемента,
# куда указывает href
$(document).on "click", "[data-toggle=scrollto]", (e) ->
  items = $(@).attr("href").split "#"
  # Прямо сейчас работают только относительные пути в href.
  # TODO: сделать поддержку полных ссылок с http(s)://domain/path#id
  if items[0] && items[0] != location.pathname
    location.href = items[0]
    # производим переход по ссылке, с хешем в конце
    return true
  else
    # производим прокрутку страницы на указанный хеш
    e.preventDefault()
    $target = $("#" + items[1])
    settings = {}
    settings["offset"] = $(@).data("scroll-offset") if $(@).data("scroll-offset")
    $(document).scrollTo($target, ANConstants.ANIM_DURATION, settings)
  end
