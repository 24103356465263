$ ->
  $('#back-to-top').click (e) ->
    e.preventDefault()
    $('#back-to-top').tooltip 'hide'
    $('body, html').animate {scrollTop: 0}, 500

  $(window).scroll ->
    if $(this).scrollTop() > 100
      $('#back-to-top').fadeIn()
    else
      $('#back-to-top').fadeOut()