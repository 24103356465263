$ ->
  $(document).on "click", "[data-link]", (e) ->
    # Производим переход по ссылке, указанной в data-link="..." только в случае
    # если [дочерний] элемент, на который кликают не является ссылкой (имеет href),
    # не является кнопкой (button, input) и не имеет слушателей на .on("click" ...)
    $target = $(e.originalEvent.target)
    doExec = true
    # получаем все родительские ноды до той, у которой стоит [data-link]
    $target.parentsUntil(@).add($target).each ->
      if doExec
        $item = $(@)
        attachedEvents = $._data(@, "events") || {}
        # Если элемент содержит события на "click", либо это ссылка, или бутстраповая кнопка,
        # то переход не нужен
        if attachedEvents["click"] || attachedEvents["mousedown"] || $item.attr("href") || $item.is("button") || $item.is("input") || $item.data("toggle")
          doExec = false

    if doExec
      e.preventDefault()
      window.location.href = $(@).data("link")

    # Чтобы событие ушло выше по DOM
    true