$ ->
  $(document).on "click", "[data-behavior=show-more]", (e) ->
    e.preventDefault()
    window.showLoadingIndicator()

    $currentLink = $(@)
    if $currentLink.data("container")
      $container = $currentLink.closest($currentLink.data("container"))
      $currentLinkContainer = $currentLink.parentsUntil($currentLink.data("container")).last()
    else
      $container = $currentLink.parent()
      $currentLinkContainer = $currentLink

    $currentLink.hide()
    $.ajax
      type: "GET"
      url: $(@).attr("href")
      dataType: "html"
      success: (resultData) ->
        $(resultData).insertBefore($currentLinkContainer)
        parts = $currentLink.attr("href").split("?page=")
        page = parseInt(parts[1]) + 1
        if page <= $currentLink.data("max-pages")
          $currentLink.show()
          $currentLink.attr("href", parts[0] + "?page=" + page)
      complete: ->
        window.hideLoadingIndicator()
      error: ->
        alert("Ошибка во время передачи данных, пожалуйста проверьте подключение к интернету и повторите попытку")
        $currentLink.show()