class window.DynamicYandexMap
  MOBILE_MAX_WIDTH: 576
  MAP_MIN_HEIGHT: 366
  MAP_PANELR_MIN_TOP: 10
  MAP_PANELR_MAX_TOP: 77
  MAP_PANELR_WIDTH: "260px"
  MAP_PANELR_RIGHT: "11px"
  MAP_PANELB_HEIGHT: "239px"
  MAP_PANELB_BOTTOM: "40px"
  MAP_PANELB_MARGINX: "15px"
  MAP_PANELB_RIGHT: "15px"
  MAP_INIT_DELAY: 300
  MAP_NORMAL_POINT_PRESET: "islands#dotIcon"
  MAP_ACTIVE_POINT_PRESET: "islands#redDotIcon"
  MAP_ACTIVE_POINT_ZINDEX: 700

  constructor: ($mainMap, $shadowMap, points, citiesCoords, currentCityLat, currentCityLon, currentCityId, mapZoom, clustering, isMobile, currentSellerId) ->
    # Первая карта, видимая изначально
    @$mainMap = $mainMap
    # Вторая карта, спрятанная за текущей, будут меняться местами с первой при перерисовке точек
    @$shadowMap = $shadowMap
    @$container = $mainMap.parent()
    @isScroll = false
    @scrollTO = 0
    @activePlacemark = null
    @prevMap = null

    @citiesCoords = citiesCoords
    @currentCityLat = currentCityLat
    @currentCityLon = currentCityLon
    @currentCityId = currentCityId
    @mapZoom = mapZoom
    @clustering = clustering
    # В мобильном виде при инициализации карты нужно открывать полноэкранный режим
    @isMobile = isMobile
    # ID продавца, на которого следует навести "фокус" при открытии карты
    @currentSellerId = currentSellerId

    @controls = []

    @initPanel().then =>
      @currentMap = @createMap(@$mainMap.attr("id"))
      @currentMap.behaviors.disable("scrollZoom")
      @drawPoints(points)
      @initEvents()
      setTimeout =>
        @afterInit()
      , @MAP_INIT_DELAY

  initPanel: () ->
    new Promise (resolve, reject) =>
      # Библиотечка из примеров яндекса https://tech.yandex.ru/maps/jsbox/2.1/sidebar
      # переделанная в coffeescript
      ymaps.modules.define "Panel", [
        "util.augment"
        "collection.Item"
      ], (provide, augment, item) =>
        # Создаем собственный класс.
        Panel = (options, owner) ->
          Panel.superclass.constructor.call this, options
          this.ownerMap = owner
          return

        # И наследуем его от collection.Item.
        augment Panel, item,
          onAddToMap: (map) ->
            Panel.superclass.onAddToMap.call this, map
            @getParent().getChildElement(this).then @_onGetChildElement, this
            # Добавим отступы на карту.
            # Отступы могут учитываться при установке текущей видимой области карты,
            # чтобы добиться наилучшего отображения данных на карте.
            @ownerMap.setMapMargins(map)
            return
          onRemoveFromMap: (oldMap) ->
            if @_$control
              @_$control.remove()
            Panel.superclass.onRemoveFromMap.call this, oldMap
            return
          _onGetChildElement: (parentDomContainer) ->
            # Создаем HTML-элемент с текстом.
            # По-умолчанию HTML-элемент скрыт.
            @_$control = $("<div class='sellers-map-panel'><div class='content'></div><div class='closeButton'></div></div>").appendTo(parentDomContainer)
            @_$content = $(".content", parentDomContainer)
            # При клике по крестику будем скрывать панель.
            $(".closeButton", parentDomContainer).on "click", (e) =>
              @_onClose(e)
            return
          _onClose: (e) ->
            @ownerMap.unsetPlacemark()
            @_$control.hide()
            return
          setContent: (text) ->
            # При задании контента будем показывать панель.
            @_$control.show()
            @_$content.html text
            @ownerMap.setPanelOrientation()
            return
        provide Panel
        return

      # Инициализируем компонент панель, затем и саму карту
      ymaps.ready(["Panel"]).then =>
        @panel = new ymaps.Panel({}, @)
        resolve()

  destroy: ->
    @currentMap.container.events.remove("fullscreenexit", @currentMap.fullScreenExitHandler)
    @currentMap.destroy()
    @$mainMap.html("")
    @$shadowMap.html("")

  removeControls: (map) ->
    map.controls.remove("typeSelector")

  initEvents: ->
    $(window).on "resize", =>
      @setPanelOrientation()
      @setMapMargins(@currentMap)
      setTimeout =>
        @currentMap.container.fitToViewport()
      , 300

    $(document).on "scroll", (e) =>
      @isScroll = true
      clearTimeout(@scrollTO) if @scrollTO > 0

      @scrollTO = setTimeout =>
        @isScroll = false
      , 100

    @$mainMap.add(@$shadowMap).hoverIntent (e) =>
      return if @isScroll
      @resizeMap()

  afterInit: ->
    # Фокусировка на компанию, если указан её ID
    if @currentSellerId && @placemarksIndex[@currentSellerId]
      @placemarksIndex[@currentSellerId].events.fire('click')

  # Изменение ориентации панели
  setPanelOrientation: ->
    if $(window).width() < @MOBILE_MAX_WIDTH
      # На узких экранах панель снизу
      @panel.options.set(position: {bottom: @MAP_PANELB_BOTTOM, left: @MAP_PANELB_MARGINX, right: @MAP_PANELB_MARGINX})
    else
      # На широких экранах панель справа, но верхний отступ рассчитывается так, чтобы на маленьких экранах
      # панель была по-центру. На больших экранах панель сверху с отступом @MAP_PANELR_MAX_TOP
      top = parseInt(($(".sellers-map:visible").height() - @panel._$control[0].clientHeight) / 2)
      top = @MAP_PANELR_MIN_TOP if top < @MAP_PANELR_MIN_TOP
      top = @MAP_PANELR_MAX_TOP if top > @MAP_PANELR_MAX_TOP
      @panel.options.set(position: {right: @MAP_PANELR_RIGHT, top: top + "px"})

  # Выставление границ "рабочей зоны" карты, API карт при центрировании будет считать центр исходя из этой зоны.
  setMapMargins: (map) ->
    # reset previous margin areas
    map.margin._accessors = []
    if $(window).width() < @MOBILE_MAX_WIDTH
      # is mobile
      map.margin.addArea
        bottom: 0
        left: 0
        right: 0
        width: "100%"
        height: @MAP_PANELB_HEIGHT
    else
      map.margin.addArea
        top: 0
        right: 0
        width: @MAP_PANELR_WIDTH
        height: "100%"

  createMap: (containerId) ->
    newMap = new ymaps.Map containerId,
      center: [@currentCityLat, @currentCityLon],
      controls: @controls,
      zoom: @mapZoom
    if @isMobile
      # Переход в полноэкранный режим
      @fullscreenControl = new ymaps.control.FullscreenControl()
      newMap.controls.add(@fullscreenControl)
      @fullscreenControl.enterFullscreen()
      newMap.fullScreenExitHandler = =>
        $(@).trigger("fullscreenexit", [{calledByUser: true}])
      newMap.container.events.add "fullscreenexit", newMap.fullScreenExitHandler
      newMap.clickHandler = (e) =>
        $(@).trigger(e.originalEvent.type, [{calledByUser: true}])
      newMap.container.events.add ["click", "mousedown", "mouseup"], newMap.clickHandler

    @removeControls(newMap)
    newMap.controls.add @panel, float: "none"
    return newMap

  # Подготовка к переключению основной и теневой карты
  prepareSwitchMaps: ->
    @prevMap = @currentMap

    containerId = null
    if @$container.hasClass("shadow-map")
      # Возвращаем основную карту
      containerId = @$mainMap.attr("id")
    else
      # Инициализируем теневую карту
      containerId = @$shadowMap.attr("id")

    @currentMap = @createMap(containerId)
    @currentMap.controls.add @panel, float: "none"
    @setPanelOrientation()

  doSwitchMaps: ->
    @$container.toggleClass("shadow-map")
    @prevMap.container.events.remove("fullscreenexit", @prevMap.fullScreenExitHandler)
    @prevMap.destroy()
    if @$container.hasClass("shadow-map")
      @$mainMap.html("")
    else
      @$shadowMap.html("")


  # Вывод меток на карту
  drawPoints: (newPoints) ->
    placemarksByCity = {}
    @placemarksIndex = {}
    newPoints.forEach (x) =>
      if !placemarksByCity[x.city]
        if @clustering
          placemarksByCity[x.city] = new ymaps.Clusterer({preset: "islands#blueClusterIcons", minClusterSize: 4})
        else
          placemarksByCity[x.city] = new ymaps.GeoObjectCollection()
        @currentMap.geoObjects.add(placemarksByCity[x.city])

        placemarksByCity[x.city].events.add "click", (e) =>
          target = e.get("target")
          @unsetPlacemark()
          @setPlacemark(target)
          @currentMap.panTo(target.geometry.getCoordinates(), {useMapMargin: true})
          # Если карта ещё не была "раскрыта" до полной высоты, то разворачиваем её
          if (@$mainMap.is(":visible") && !@$mainMap.hasClass("hover")) || (@$shadowMap.is(":visible") && !@$shadowMap.hasClass("hover"))
            @resizeMap()

      placemark = new ymaps.Placemark([parseFloat(x.latlon.latitude), parseFloat(x.latlon.longitude)], {panelContent: @makePanelContent(x)}, {preset: @MAP_NORMAL_POINT_PRESET})
      placemark.options.set("cityId", x.city)
      placemarksByCity[x.city].add(placemark)
      @placemarksIndex[x.id] = placemark

  setPlacemark: (placemark) ->
    @activePlacemark = placemark
    placemark.options.set("preset", @MAP_ACTIVE_POINT_PRESET)
    placemark.options.set("zIndex", @MAP_ACTIVE_POINT_ZINDEX)
    @panel.setContent(placemark.properties.get("panelContent"))

  unsetPlacemark: ->
    if @activePlacemark
      @activePlacemark.options.set("preset", @MAP_NORMAL_POINT_PRESET)
      @activePlacemark.options.set("zIndex", null)

  # Изменение меток, перерисовка и подмена карты
  setPoints: (newPoints) ->
    @prepareSwitchMaps()
    @drawPoints(newPoints)
    @currentMap.setCenter([@currentCityLat, @currentCityLon], @mapZoom)
    @doSwitchMaps()

  # Получение HTML-кода для панели
  makePanelContent: (point)->
    "<div class='balloonContent seller-balloon container-fluid'>" +
    "<div class='row'>" +
    "<div class='icons row mb-3'>" + point.vendor_icons + "</div>" +
    "</div>" +
    "<div class='row'>" +
    (if point.img_tag then "<div class='d-none d-sm-flex'>" + point.img_tag + "</div>" else "") +
    "<div class='balloonText col px-0'>" +
    "<h2 class='mb-2 pb-1 my-sm-3 pb-sm-0'><a href='" + point.url + "'>" + point.name + "</a></h2>" +
    "<p><strong>Адрес:</strong> <br class='d-none d-sm-inline'>" + point.address + "</p>" +
    "<p><strong>Телефон:</strong> <br class='d-none d-sm-inline'>" + point.phones + "</p>" +
    "</div>" +
    "</div>" +
    "</div>"

  resizeMap: ->
    $(".sellers-map").addClass("hover")
    @currentMap.behaviors.enable("scrollZoom")
    interval = setInterval =>
      @currentMap.container.fitToViewport()
    , 10
    setTimeout =>
      clearTimeout(interval)
      @currentMap.container.fitToViewport()
      @setPanelOrientation()
    , 400
