$ ->
  # трекаем события data-behavior и отправляем их в аналитикс
  $(document).on "click", "[data-behavior]", (e)->
    try
      return true unless window.gtagGaEvent

      # Если задан data-behavior-wait-ga, то сперва ждем ответ от аналатикса, а потом вызываем событие заново.
      # Эта штука нужна, если кликают по ссылке и мы переходим на другую страницу. Но при этом браузер отменяет отправку
      # события в аналитикс.
      if $(@).attr('data-behavior-wait-ga')
        if $(@).attr('data-behavior-wait-ga-in-progress')
          $(@).removeAttr('data-behavior-wait-ga-in-progress')
          return true

        # оставляем подсказку в консоле, чтобы потом разобраться из-за чего какой-то data-behavior вдруг перестал работать
        console?.log('Call e.preventDefault() because set attribute `data-behavior-wait-ga`')
        e.preventDefault()
        $(@).attr('data-behavior-wait-ga-in-progress', true)

        element = @
        callback = ->
          if $(element).attr('href')
            # по причинам безопасности в браузере $('a').trigger('click') не переходит по ссылке. Костылим.
            window.location.href = $(element).attr('href')
          else
            # а это оставляем если кликнули по кнопке формы
            $(element).trigger('click')
      else
        callback = null

      label = $(@).attr('data-behavior')
      value = $(@).attr('data-behavior-name')
      window.gtagGaEvent(eventCategory: 'Behavior', eventAction: label, eventLabel: value, eventCallback: callback, eventTimeout: 1000)


  # отправляем видимые баннера,
  # юзаем фильтр in-viewport, который любезно предоставлен jquery.lazyload.js > 1.9.3
  $('.bnr-wide, .bnr-txt, .bnr-tall').each ->
    return true unless window.gtag # если нет аналитикса, то ничего не делаем

    check_if_visible_every_ms = 3000

    $bnr = $(@)
    interval = setInterval ->
      if $bnr.is(":in-viewport")
        clearInterval interval
        try
          label = $bnr.attr('data-behavior').replace("click-", "view-")
          value = $bnr.attr('data-behavior-name')
          window.gtagGaEvent(eventCategory: 'Views', eventAction: label, eventLabel: value)
    , check_if_visible_every_ms
