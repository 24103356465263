$ ->
  browserWindow = ->
    dbody = document.body
    delem = document.documentElement
    ww = window

    if (dbody && dbody.offsetWidth)
      winw = dbody.offsetWidth
      winh = dbody.offsetHeight

    if (document.compatMode == 'CSS1Compat' && delem && delem.offsetWidth)
      winw = delem.offsetWidth
      winh = delem.offsetHeight

    if (ww.innerWidth && ww.innerHeight)
      winw = ww.innerWidth
      winh = ww.innerHeight

    dcw = dbody.clientWidth
    dch = dbody.clientHeight
    ddcw = delem.clientWidth
    ddch = delem.clientHeight

    cs_window = {width: winw, height: winh}
    if (ddcw > dcw && ddcw < winw)
      cs_window.innerWidth = ddcw
    else
      cs_window.innerWidth = dcw

    if ((ddch > dch && ddch <= winh) || (ddch < dch && ddch > 0))
      cs_window.innerHeight = ddch
    else
      cs_window.innerHeight = dch

    cs_window

  destroy_web_consult = ->
    window.ConsultSystems.needReload = false
    window.ConsultSystems.div = false
    window.ConsultSystems.opened = false
    if(window.ConsultSystemsNod? and window.ConsultSystemsNod.urlCheckInterval)
      clearInterval(window.ConsultSystemsNod.urlCheckInterval)
    $('#consultsystems').remove()

  window.track_pageview_web_consult = (siteHash, title='') ->
    if siteHash
      title = window.document.title unless title
      unless window.consultsystemsVisit?
        window.consultsystemsVisit = ->
      url = "https://consultsystems.ru/script/im/visit.php?callback=consultsystemsVisit&sitehash="+siteHash+"&title=" + encodeURIComponent(title.substr(0,100)) + "&tid=4&referer=&rnd="+(new Date).getTime()
      $.ajax
        url: url,
        dataType: 'jsonp'


  init_web_consult = (id, hash, title, clickedElement) ->
    if window.ConsultSystemsNod.siteId != id
      destroy_web_consult()
      window.ConsultSystemsNod.siteId = id
      window.ConsultSystemsNod.siteHash = hash
      window.ConsultSystemsNod.windowTitle = title


    CS = window.ConsultSystems
    if CS
      # задаем размер окна, если он еще не задан через куки
      unless CS.savedSize? and CS.savedSize[0]
        CS.savedSize = [440,350]

      # инициализируем ДОМ-элементы
      CS.styleInit()

      # получаем размеры рабочей области
      ww = browserWindow()

      # получаем координаты, размещения окна
      x = ww.innerWidth - 30 - (CS.savedSize[0] || CS.min[0])
      y = ww.innerHeight - 30 - (CS.savedSize[1] || CS.min[1])

      # хакаем переменные в Вебконсалте, чтобы позиционирование правильно отработало
      CS.savedOffset.x = CS.savedOffset.x || x
      CS.savedOffset.y = CS.savedOffset.y || y
      CS.isFixed = false

      # отображаем говорилку
      CS.show(x, y, clickedElement)


  $(document).on "click", "[data-behavior=open-web-consult]", (e, data)->
    e.preventDefault()
    e.stopPropagation()

    $self = $(@)

    init_web_consult($self.data('im-id'), $self.data('im-hash'), $self.data('im-title'), @)