class ANStateMachine
  constructor: ->
    @list = null
    @model = null
    @partial = null
    @contents = null
    @initialized = false

    @initialUrl = decodeURI(document.location.href)
    @initialTitle = $(document).find("title:eq(0)").text()

    window.onpopstate = $.proxy (e)->
      @.onStateChange(data: e.state || {})
    , @

    $(window.document).ajaxSend (event, jqXHR, ajaxOptions)=>
      jqXHR.setRequestHeader("X-An-Referer", encodeURI(@initialUrl))

  setTitle: (title)->
    if title
      document.title = title
      $(@).trigger("change-title")

  pushState: (state, url)->
    window.history.pushState(state, null, url)
    @.onStateChange(data: state, url: url)

  replaceState: (state, url)->
    window.history.replaceState(state, null, url)
    @.onStateChange(data: state, url: url)

  getState: ->
    window.history.state

  initState: (state)->
    @replaceState(state, @initialUrl)

  onStateChange: (state)->
    data = state.data
    if data.initialize && !@initialized
      @initialized = true
      # не нужно ничего делать на самый первый replaceState, который
      # задаёт начальные данные состояния открытой страницы
      return

    if data.model && (model = if @list then @list.modelsMap[data.model] else if @model && @model.id == data.model then @model)
      model.open().done =>
        # Если данные состояния в history содержат данные для ANModelCompareController
        # и этот контроллер уже был инициализирован на текущей странице,
        # то меняем состояние этого контроллера. В противном случае скрипты в HTML
        # на странице сами создадут этот контроллер.

        if data.compare || data.tab == 'compare'
          model.openTab('compare').done =>
            if @compare
              @compare.open(data.compare)
        else if data.tab
          model.openTab(data.tab).done =>
            if data.content
              model.tabContents[data.tab].openContent(data.content).done =>
                @setTitle(model.tabContents[data.tab].titles[data.content])
            else
              model.tabContents[data.tab].closeOpenedContent()
              @setTitle(model.tabTitles[data.tab] || @initialTitle)
        else
          model.openTab("").done =>
            if data.variant
              variant = model.variantsMap[data.variant]
              if variant
                variant.open().done =>
                  @setTitle(variant.title)
                  if data.variantTab
                    variant.toggleTab(data.variantTab)
                  else
                    variant.toggleTab(null)

                  if data.calc
                    variant.openCalc(data.calc)
                  else
                    variant.openCalc(null)

                  $(@).trigger("variant_open")
              else
                window.document.location = state.url
            else
              @setTitle(model.title)
              model.openedVariant.close() if model.openedVariant
            $(@).trigger("model_open")
      return
    else if data.partial && @partial
      @partial.open(data.partial, state.url)
      return
    else if @model
      @model.close()
      @setTitle(@initialTitle)
      return

    else if @contents
      if data.content
        @contents.openContent(data.content).done =>
          @setTitle(@contents.titles[data.content])
      else
        @contents.closeOpenedContent()
        @setTitle(@initialTitle)
      return

    @setTitle(@initialTitle)
    window.document.location = state.url

window.stateMachine = new ANStateMachine()