$ ->
  $(document).on 'click', '[data-behavior=contact-form-open]', (e, data)->
    $(this).hide()
    contactFormLoad = (data)->
      $(this).removeClass('loading').css(height: 'auto').find('.contact-form-wrap').css height: 'auto'
      $(this).find('form').submit ->
        $.ajax
          data: $(this).serializeArray(),
          url: this.action, 
          type: 'POST', 
          success: (data)->
            contactFormLoad(data)       
        return false
    $('#contact-form').find('.contact-form-wrap').css(height: 500).end().css(height: 500).addClass('loading').show().load this.href, ->
      $(this).removeClass('loading').css(height: 'auto').find('.contact-form-wrap').css height: 'auto'

    return false

  show_alert = (msg, status = 'success') ->
    $('#contact-form_alerts').remove();
    alert = $('<div id="contact-form_alerts"></div>')
    $('#contact-form').after(alert)
    alert.append('<span class="alert alert-' + status + '">' + msg + '</span>')

  $(document).on 'ajax:before', '#contact-form', ->
    $('#contact-form').addClass 'loading'
    $('#contact-form').find('input[type=submit]').attr 'disabled', 'disabled'

  $(document).on 'ajax:success', '#contact-form', (e) ->
    $('#contact-form').removeClass 'loading'
    $('#contact-form').html e.detail[2].responseText
    if $('#contact-form .field-with-errors').length
      $('#contact-form .field-with-errors:first input').focus()


  $(document).on 'ajax:error', '#contact-form', ->
    $('#contact-form').find('input[type=submit]').removeAttr 'disabled'
    $('#contact-form').removeClass 'loading'
    alert 'Извините, но при отправке заявки возникла ошибка. Немного подождите и попробуйте снова.'
