$ ->
  $("[data-behavior='hide-long-text']").each ->
    $element       = $(this)
    $elementHeight = $element.height()
    $elementHeightThreshold = 54

    if $elementHeight > $elementHeightThreshold
      $element.addClass 'hiden-long-text'

      $element.click ->
        $(this).removeClass 'hiden-long-text'