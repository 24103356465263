class window.ANModelController
  constructor: (controller, id, handle, panel, url, title)->
    @id = id
    @controller = controller
    @$handle = $(handle)
    @$panel = $(panel)
    @url = url || decodeURI(@$handle.attr("href"))
    @title = title || null
    @tabTitles = {}
    @tabContents = {}
    @variantsMap = {}
    @openedVariant = null

    @$handle.on "click", $.proxy((e)->
      e.preventDefault()

      if $(e.currentTarget).closest("article").hasClass("car-item-current")
        window.stateMachine.pushState({}, window.stateMachine.initialUrl)
      else
        window.stateMachine.pushState({model: @id}, @url)
    , @)

    @controller.modelsMap[id] = @ if @controller

    @initTabs() unless @$panel.size() == 0

    $openedVariant = $(".current[data-behavior='open-variant'] + .tabs-content-description")

    if @$handle.size() == 0 && $(document).scrollTop() == 0 && !window.stateMachine.compare
      if $openedVariant.size() > 0
        # Перемотка страницы вниз до открытой комплектации при открытии комплектации
        # не через AJAX, а по-ссылке напрямую.
        $(window).one "load", ->
          $.scrollIntoView($openedVariant.prev(), ANConstants.TR_TOP_MARGIN, ANConstants.ANIM_DURATION)
      else
        # Перемотка вниз до открытой модели
        $(window).one "load", =>
          $.scrollIntoView(@$panel, ANConstants.TR_TOP_MARGIN, ANConstants.ANIM_DURATION)

    if $openedVariant.size() == 0 && !window.stateMachine.compare && !controller
      # Фикс бага с кнопкой "Назад"
      window.stateMachine.replaceState({model: id, initialize: true}, decodeURI(location.href))

  open: ->
    deferred = $.Deferred()

    return deferred.resolve() unless @$panel.size() == 0

    @unhighlightVariant()

    $article = @$handle.closest("article")

    if window.stateMachine.model
      # компенсируем (мгновенно прокручивая окно) высоту скрываемого блока другой марки, если он выше текущего
      $prevArticle = window.stateMachine.model.$handle.closest("article")
      $prevContent = window.stateMachine.model.$panel

      prevHeightDiff = 0
      prevArticleWasVisible = $prevArticle.is(":visible")
      $prevArticle.removeClass("car-item-current")

      if $prevArticle.size() > 0 and not prevArticleWasVisible
        prevHeightDiff = $prevArticle.outerHeight(true)

      prevHeight = if $prevContent.is(":visible") then $prevContent.outerHeight(true) - prevHeightDiff else 0
      prevOffsetTop = $prevContent.offset().top if $prevContent.offset()

      if $article.offset().top > prevOffsetTop
        $(document).scrollTop($(document).scrollTop() - prevHeight)

      window.stateMachine.model.close()

    $article.addClass("car-item-current")

    # сконструируем новый блок с контентом модели
    @$panel = $("""<div class="car-item-wrapper content open-car row loading"></div>""")

    if (availableHeight = window.innerHeight) and availableHeight > 0
      @$panel.css("height", Math.min(availableHeight, 1000) - ANConstants.TR_TOP_MARGIN)

    controller = @
    @$panel.load randomizedUrl(@.url), ->
      controller.title = controller.$panel.find("title").text()
      $.Fotorama.cache = {} if $.Fotorama
      $close = $("""<a href="#close" class="car-item-current-close btn-close"></a>""").on "click", $.proxy(((e)-> e.preventDefault(); @.$handle.trigger "click"), controller)
      controller.$panel
      .css("height", "auto")
      .removeClass("loading")
      .find(".car-item-current-cont:eq(0)")
      .append($close)
      .find(".fotorama:eq(0)")
      .fotorama()

      controller.initTabs()
      deferred.resolve()

    # вставим, проскроллим, всякая пост-шняга
    @$panel.insertAfter($article.closest(".section")).show()
    $.scrollIntoView(@$panel, ANConstants.TR_TOP_MARGIN, ANConstants.ANIM_DURATION)
    window.stateMachine.model = @

    return deferred

  close: ->
    @$handle.closest("article").removeClass("car-item-current")
    @$panel.remove()
    @$panel = $()
    window.stateMachine.model = null

  initTabs: ->
    controller = @

    @$panel.find("[data-behavior=open-model-tab]").on "click", (e)->
      e.preventDefault()
      return if $(@).closest("li").hasClass("active")
      window.stateMachine.pushState({model: controller.id, tab: $(@).data("tab")}, decodeURI(@.href))
      # Прокручиваем страницу до области с вкладками если они находятся вне зоны видимости
      # вверх или вниз, без разницы
      $.scrollIntoView($("#up300"), ANConstants.TR_TOP_MARGIN, ANConstants.ANIM_DURATION)

    @$panel.find(".tabs-content").each (idx, tabDiv)=>
      $tabDiv = $(tabDiv)
      if $tabDiv.is(":visible")
        if $tabDiv.hasClass("tabs-content-")
          @initVariants()
        else
          @initContentTab($tabDiv)

  initContentTab: ($container)->
    tab = $container.get(0).className.match(/tabs-content-([^ $]*)/)[1]
    $handle = @$panel.find("[data-behavior=open-model-tab][data-tab=#{tab}]")
    @tabContents[tab] = new window.ANContentController($container, {model: @id, tab: tab}, decodeURI($handle.attr("href")))

  initVariants: ->
    controller = @

    @$panel.find("a[data-behavior=open-variant]").each ->
      $link = $(@)
      $row = $link.closest(".row.link")
      if $row.hasClass("current")
        $panel = $row.next(".tabs-content-description")
      else
        $panel = $()

      variant = new window.ANModelVariantController(controller, $link.data("id"), $link, $panel, null, controller.title)
      controller.openedVariant = variant unless $panel.size() == 0

    @$panel.find("[data-behavior=open-variant]").on "click", (e)->
      e.preventDefault()
      targetNodeName = e.target.nodeName.toLowerCase()
      return if targetNodeName == "a" || $(e.target).parent().is("a[data-behavior=open-variant]")
      $(e.target).closest(".row.link").find("a").trigger("click")

    if controller.openedVariant
      # Фикс бага с кнопкой "Назад" для страницы комплектаций
      currentState = window.stateMachine.getState()
      if !currentState || !currentState['variant']
        window.stateMachine.replaceState({model: @id, variant: controller.openedVariant.id, initialize: true}, location.href)


  openTab: (tab)->
    controller = @
    deferred = $.Deferred()
    $tabsPanel = @$panel.find(".car-tabs:eq(0)")
    $link = $tabsPanel.find("a[data-tab='#{tab}']")
    $li = $link.closest("li")

    @$panel.find("[data-show-if-tab-open]").hide()
    deferred.done ->
      controller.$panel.find("[data-show-if-tab-open='#{tab}']").show()

    return deferred.resolve() if $li.hasClass("active")

    $tabsPanel.find(".active").removeClass("active")
    $li.addClass("active")

    @$panel.find(".tabs-content").hide()
    $content = @$panel.find(".tabs-content-#{tab}").show()

    if $content.data("loaded")
      deferred.resolve()
    else
      $content.data("loaded", true).addClass("loading")
      $.ajax
        url: $link.attr("href"),
        data: {only_content: true}, # чтобы первая вкладка с комплектациями нормально загрузилась
        success: (response)->
          $content.html(response)
          controller.tabTitles[tab] = $content.find("title").text()
          if tab
            controller.initContentTab($content)
          else
            controller.title = $content.find("title").text()
            controller.initVariants()
        complete: ->
          $content.removeClass("loading")
          deferred.resolve()
        error: ->
          $content.html("<table><tr><td>Ошибка загрузки, обновите страницу и попробуйте еще раз.</td></tr></table>")
          $content.data("loaded", false)

    return deferred

  highlightOpenedVariant: ->
    @openedVariant?.$handleContainer.addClass("highlight")

  unhighlightVariant: ->
    @$panel.find(".highlight").removeClass("highlight")